import './App.css';
import Header from './components/Header/Header';
import Login from './components/Login/Login';
import LoginForm from "./components/LoginForm/LoginForm";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Events from './components/Events/Events';
import EventPage from './components/EventPage/EventPage';
import MyEventsPage from './components/MyEventsPage/MyEventsPage';
import CreateEvent from "./components/CreateEvent/CreateEvent";
import Dashboard from './components/Dashboard/Dashboard';
import ParticipantDetails from './components/ParticipantDetails/ParticipantDetails';
import Nav from './components/nav/Nav'
import Students from './components/Students/Students';
import TutorLanding from './components/TutorLanding/TutorLanding'
import CourseForm from './components/CourseForm/CourseForm'
import CoursePage from './components/CoursePage/CoursePage'

function App() {
  return (
    <div className="App">
      <Router>
        <Nav />
        <Switch>
          <Route path='/events/:id/:category'>
            <ParticipantDetails />
          </Route>
          {/* <Route path='/compete/:id/:category'>
            <EventPage/>
          </Route> */}
          <Route path='/events'>
            <Events />
          </Route>
          <Route path='/students'>
            <Students />
          </Route>
          <Route path='/' exact>
            <TutorLanding />
            {/* <Login/> */}
          </Route>
          <Route path='/Dashboard' exact>
            <Dashboard />
          </Route>
          <Route path='/courses' exact>
            <CoursePage />
          </Route>
          <Route path='/courses/create' exact>
            <CourseForm />
          </Route>
          <Route path='/courses/update/:courseId/:state' exact>
            <CourseForm />
          </Route>
          <Route path='/myevents' exact>
            <MyEventsPage />
          </Route>
          <Route path='/myevent/create' exact>
            <CreateEvent />
          </Route>
          <Route path='/login' exact>
            <LoginForm />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

import React from "react";
import styles from "./TutorLanding.module.css";
import { FullPage, Slide } from 'react-full-page';
import imgpath from "../../assets/butterfly.png";
import dummyTeacher from "../../assets/dummyTeacher.png";
import layer1 from "../../assets/layer1.png";
import layer2 from "../../assets/layer2.png";
import element1 from "../../assets/Element1.png";
import element2 from "../../assets/Element2.png";
import element3 from "../../assets/Element3.png";
import element4 from "../../assets/Element4.png";
import element5 from "../../assets/Element5.png";
import inDepth1 from "../../assets/laptop.png";
import inDepth2 from "../../assets/presentation.png";
import inDepth3 from "../../assets/teach_icon.png";
import inDepth4 from "../../assets/trophy.png";
import inDepth5 from "../../assets/pair-of-bills.png";
import { auth } from "../../firebase";
import { useEffect } from "react";

import { useHistory } from "react-router";
import { blue } from "../../uicomponents/color";
const TutorLanding = () =>{ 

  const history = useHistory();

  const goToSignup=()=>{
    history.push('/login');
  }

  useEffect(() => {
	
			if (auth.currentUser) {
			
				// setHostId(user.uid)
				
				console.log('Yes1');
				history.push('/courses')
			
				// ...
			
			}
	
	}, []);
  
  return(
  <div className={styles.TutorLanding}>
   
    <div className={styles.firstPageDiv}>
    <img src={element1} className={styles.element1}></img>
   
    <img src={element2} className={styles.plus1}></img>
    <img src={element2} className={styles.plus2}></img>
    <img src={element3} className={styles.circle1}></img>
    <img src={element3} className={styles.circle2}></img>
      
      <div className={styles.dummyTeacher}>
        <img src={dummyTeacher} />
      </div>
      <div className={styles.rightFirst}>
        <div className={styles.firstuppercaseLines} >
          <div className={styles.headerPageOne}>
          <h1>
            TEACH <span className={styles.bluish}>MORE</span>
          </h1>
          <h1>
            REACH MANY <span className={styles.pinkfont}>MORE</span>
          </h1>
          <h1 className={styles.catchyheading}>
            EARN <span className={styles.bluish}>MORE</span>
          </h1>
          </div>

          <p className={styles.rightFirstpara}>
            xoog empowers the teacher to build personal brand website, List
            their programs,conduct competitions, get referrals, influence
            students to get more enrollments
          </p>

          <button className={styles.pinkButton} onClick={goToSignup}>Signup</button>
        </div>

  

      </div>

      <div className={styles.lowerImg}>
    
          <img src={element5} className={styles.element5}></img>
          <img src={element4} className={styles.element4}></img>
      </div>
    </div>
    
 

    <div className={styles.secondPageDiv}>
     
      <div className={styles.displayrow}>
        <div className={styles.leftSecond}>
          <img src={layer1} />
          <div className={styles.uppercaseLines}>
            <p>
              List your
              <span className={styles.bluish}> Learning courses </span>
              and
            </p>
            <p>
              <span className={styles.pinkfont}>Get More students</span> and and
              <span className={styles.bluish}> Personal</span>
            </p>
            <p>
              <span className={styles.bluish}> Branding </span>
              and
              <span className={styles.pinkfont}> Website</span>
            </p>
          </div>
        </div>
        <div className={styles.rightSecond}>
          <img src={layer2} />
          <div className={styles.uppercaseLines}>
            <p>
              Conduct
              <span className={styles.bluish}> Competitons and Webinars</span>
            </p>
            <p>and get Leaderboard for results</p>
            <p>
              <span className={styles.pinkfont}>
                Earn through Registration Fee
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className={styles.bottomDiv}>
        <h1 className={styles.boldText}>Join our Community </h1>
      
        <h1 className={styles.boldText}><span style={{color:blue}}>100+</span> Tutors and creators</h1>

        <h1 className={styles.boldText}><span style={{color:blue}}>1000+</span> Students</h1>

        <button className={styles.pinkButton} onClick={goToSignup}>Alright! Signup me up</button>
      </div>
  
    </div>
    

    <div className={styles.thirdPagediv}>
      <h1 className={`${styles.pinkfont} ${styles.headerpagethree}`}>
        LET'S LOOK AT IT IN DEPTH!
      </h1>

      <div className={styles.cardsDetails}>
        <div className={styles.card}>
          <img src={inDepth1} className={styles.inDepthImg} />
          <div className={styles.thirdDescription}>
            <p>Personal Branding</p>
            <p>wesbite for your</p>
            <p>courses Improve</p>
            internet presence
            <p></p>
          </div>
        </div>

        <div className={styles.card}>
          <img src={inDepth2} className={styles.inDepthImg} />
          <div className={styles.thirdDescription}>
            <p>Get more students</p>
            <p>Teach More students</p>
            <p>however you want!</p>
          </div>
        </div>

        <div className={styles.card}>
          <img src={inDepth3} className={styles.inDepthImg} />
          <div className={styles.thirdDescription}>
            <p>List your offerings</p>
            <p>as courses under your</p>
            <p>profile so students can</p>
            <p>connect with you</p>
          </div>
        </div>

        <div className={styles.card}>
          <img src={inDepth4} className={styles.inDepthImg} />
          <div className={styles.thirdDescription}>
            <p>Conduct Competitions</p>
            <p>Events. Get registration</p>
            <p>and payment page for</p>
            <p>participants</p>
          </div>
        </div>

        <div className={styles.card}>
          <img src={inDepth5} className={styles.inDepthImg} />
          <div className={styles.thirdDescription}>
            <p>Review students Help</p>
            <p>them build a better profile</p>
            <p>Earn more through</p>
            <p>fees</p>
          </div>
        </div>
      </div>
      <div className={styles.signupbuttondiv}>
        <button className={styles.pinkButton} onClick={goToSignup}>Signup</button>
      </div>
    </div>
   
  
  </div>
);

}

TutorLanding.propTypes = {};

TutorLanding.defaultProps = {};

export default TutorLanding;

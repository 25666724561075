import React, { useEffect, useState } from "react";
import { auth, db } from "../../firebase";
import CardsDisplay from "../CardsDisplay/CardsDisplay";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import LoginForm from "../LoginForm/LoginForm";
import Loader from "../Loader/Loader";
import LearningModuleCard from "../LearningModuleCard/LearningModuleCard";
import styles from "./CoursePage.module.css";

const CoursePage = () => {
  const history = useHistory();
  const [tutorId, setTutorId] = useState("");
  const [authorised, setAuthorised] = useState(<Loader />);
  const [uncompletedCourses, setUncompletedCourses] = useState([]);
  const [unActive, setUnActive] = useState([]);
  const [active, setActive] = useState([]);

  const user = auth.currentUser;

  useEffect(() => {
    var uncompletedCourse = [];
    db.collection("XOOG_Learning")
      .where("tutor", "==", tutorId)
      .where("isCompletelyCreated", "==", false)
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          uncompletedCourse.push({ id: doc.id, data: doc.data() });
        });
        setUncompletedCourses(uncompletedCourse);
      });
  }, [tutorId]);

  useEffect(() => {
    var unactive = [];
    db.collection("XOOG_Learning")
      .where("tutor", "==", tutorId)
      .where("isCompletelyCreated", "==", true)
      .where("isXoogVerified", "==", false)
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          unactive.push(
            <LearningModuleCard
              history={history}
              moduleObj={{ id: doc.id, data: doc.data() }}
              showTutor={false}
            />
          );
        });
        setUnActive([
          {
            cardsList: unactive,
            header: "Courses Under Verification",
            coloredText: null,
            fallbackText: "No Courses",
            xScrollable: true,
          },
        ]);
      });
  }, [tutorId]);

  useEffect(() => {
    var active = [];
    db.collection("XOOG_Learning")
      .where("tutor", "==", tutorId)
      .where("isXoogVerified", "==", true)
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          active.push(
            <LearningModuleCard
              history={history}
              moduleObj={{ id: doc.id, data: doc.data() }}
              showTutor={false}
            />
          );
        });
        setActive([
          {
            cardsList: active,
            header: "Live Courses",
            coloredText: null,
            fallbackText: "No Courses",
            xScrollable: true,
          },
        ]);
      });
  }, [tutorId]);

  useEffect(() => {
    if (auth.currentUser) {
      setTutorId(user.uid);
      setAuthorised(null);
    } else setAuthorised(<LoginForm />);
  }, [auth.currentUser]);

  if (authorised) return authorised;

  return (
    <div className={styles.CoursePage}>
      <div className={styles.createCourse}>
        <div className={styles.createCourseButton}>
          <Button style={{backgroundColor:"#64d8e0", fontWeight:"900"}} onClick={() => history.push("/courses/create")} variant="contained" size="large">
            Create course
          </Button>
        </div>
        <div className={styles.uncompletedCourses}>
          {uncompletedCourses.map((val, index) => {
            return (
              <div
                className={styles.uncompletedCoursesList}
                onClick={() => {
                  history.push(
                    `/courses/update/${val.id}/${val.data.completedTill}`
                  );
                }}
              >
                {val.data.ModuleName}
              </div>
            );
          })}
        </div>
      </div>

      {/* Unactive courses */}
      <div className={styles.unActiveCourses}>
        <CardsDisplay cardsContainerList={unActive} />
      </div>
      {/* active courses */}
      <div className={styles.activeCourses}>
        <CardsDisplay cardsContainerList={active} />
      </div>
    </div>
  );
};

export default CoursePage;

export const setAuth = () => {
    return {
      type: "SET_USER_VALID"
    };
  };

export const setIsLoginPage = (isLogin)=>{
  return{
    type: "SET_IS_LOGIN_PAGE",
    payload:isLogin
  };
};

export const setEventDetails = (EventDetails)=>{
    return {
        type: "SET_EVENT_DETAILS",
        payload:EventDetails
    };
} ;

export const setCourseDetails = (courseDetails)=>{
  return {
    type: "SET_COURSE_DETAILS",
    payload:courseDetails
  }
}

export const getCourseDetails = () =>{
  return {
    type: "GET_COURSE_DETAILS"
  };
}



import React, { useEffect, useState } from "react";
import styles from "./CreateEvent.module.css";
import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { db, storage, auth } from "../../firebase";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Box from "@material-ui/core/Box";
import LoginForm from "../LoginForm/LoginForm";
import Loader from "../Loader/Loader";

const CreateEvent = () => {
  const match = useParams();
  const [formState, setFormState] = useState(1);
  const [highLights, setHighLights] = useState([]);
  const [imgUrl, setImgUrl] = useState();
  const [progress, setProgress] = useState(0);
  const [fileToUpload, setFileToUpload] = useState("");
  const [topics, setTopics] = useState([]);
  const [moduleRefId, setModuleRefId] = useState();
  const [faqArray, setFaqArray] = useState([]);
  const [tutorId, setTutorId] = useState("");
  const [authorised, setAuthorised] = useState(<Loader />);
  const [formData, setFormData] = useState({
    ModuleName: "",
    quickDescription: "",
    courseDuration: "",
    orgName: "",
    websiteurl: "",
    enrollmentFee: "",
    typeOfCourse: "",
    description: "",
    highlight: "",
    topicHeading: "",
    topicDuration: "",
    topicSummary: "",
    instructorName: "",
    instructorDetails: "",
    faqQuestion: "",
    faqAnswer: "",
  });



  //upload Host Profile Image
  const handleImgUpload = () => {
    let mainFolder;
    let filename;
    let id;
    if (formState == 3) {
      mainFolder = "courses";
      filename = "courseImg";
      id = moduleRefId;
    } else if (formState == 5) {
      mainFolder = "hosts";
      filename = "proImg";
      id = auth.currentUser.uid;
    }

    if (fileToUpload) {

      const uploadTask = storage
        .ref(`xoogplus/${mainFolder}/${id}/${filename}`)
        .put(fileToUpload);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (err) => {
          console.log(err);
        },
        () => {
          storage
            .ref(`xoogplus/${mainFolder}/${id}`)
            .child(`${filename}`)
            .getDownloadURL()
            .then((url) => {
              setImgUrl(url);

              if (formState == 3) {
                db.collection("XOOG_Learning").doc(moduleRefId).set(
                  {
                    courseHighlights: highLights,
                    courseImgLink: url,
                    completedTill: 3,
                  },
                  { merge: true }
                );
                setFileToUpload("");

              }

              if (formState == 5) {
                db.collection("XOOG_host")
                  .doc(id)
                  .set({ profileImgLink: url }, { merge: true });

                db.collection("XOOG_Learning")
                  .doc(moduleRefId)
                  .set({
                    completedTill: 5
                  }, { merge: true });
              }
            });
        }
      );
    }
  };

  const handleImageFile = (e) => {
    setFileToUpload(e.target.files[0]);
    console.log(fileToUpload);
  };

  const handleHighlights = () => {
    setHighLights((oldArray) => [...oldArray, formData.highlight]);
    setFormData({
      ...formData,
      highlight: "",
    });
  };

  const handleTopics = () => {
    const data = {
      title: formData.topicHeading,
      duration: formData.topicDuration,
      description: formData.topicSummary,
    };
    setTopics((oldArray) => [...oldArray, data]);
    setFormData({
      ...formData,
      topicHeading: "",
      topicDuration: "",
      topicSummary: "",
    });
  };
  const handleFAQs = () => {
    const data = {
      question: formData.faqQuestion,
      answer: formData.faqAnswer,
    };
    setFaqArray((oldArray) => [...oldArray, data]);
    setFormData({
      ...formData,
      faqQuestion: "",
      faqAnswer: "",
    });
  };

  const handleForm = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRadioCourseType = (e) => {
    setFormData({
      ...formData,
      typeOfCourse: e.target.value,
    });
  };

  const updateTutor = () => {
    db.collection("XOOG_host")
      .doc(auth.currentUser.uid).set({
        HostName: formData.instructorName,
        bio: formData.instructorDetails,
      }
        , { merge: true })
      .then(() => {
        handleImgUpload();
      })
      .catch((error) => console.log(error));
  };

  const SaveForm = () => {
    if (formState === 1) {
      if (
        formData.ModuleName === "" ||
        formData.quickDescription === "" ||
        formData.courseDuration === "" ||
        formData.orgName === "" ||
        formData.typeOfCourse === ""
      )
        alert("please fill all required fields");
      else if (
        formData.typeOfCourse === "Paid" &&
        formData.enrollmentFee === ""
      )
        alert("please fill all required fields");
      else {
        db.collection("XOOG_Learning")
          .add({
            ModuleName: formData.ModuleName,
            quickDescription: formData.quickDescription,
            courseDuration: formData.courseDuration,
            orgName: formData.orgName,
            websiteurl: formData.websiteurl,
            enrollmentFee: formData.enrollmentFee,
            typeOfCourse: formData.typeOfCourse,
            isCompletelyCreated: "false",
            completedTill: 1,
            isXoogVerified: "false",
            isXoogPremium: "false",
            tutor: tutorId,
          })
          .then((ref) => {

            setModuleRefId(ref.id);
            db.collection("XOOG_host").doc(auth.currentUser.uid).get().then((doc) => {
              if (typeof doc.data().coursesTutored == "undefined") {
                var refArr = [ref.id];
                db.collection("XOOG_host").
                  doc(auth.currentUser.uid).set({
                    coursesTutored: refArr
                  },
                    { merge: true }
                  );
              } else {
                // get the existing topic array
                let courseArray = doc.data().coursesTutored;
                // push new topics
                courseArray.push(ref.id);
                // update firestore
                db.collection("XOOG_host").
                  doc(auth.currentUser.uid).update({
                    coursesTutored: courseArray
                  }).then(() => {
                    console.log("New course added");
                  });
              }
            })
          })
          .catch((error) => console.log(error));
        setFormState(formState + 1);
      }
    } else if (formState === 2) {
      if (formData.description === "") alert("enter description");
      else {
        db.collection("XOOG_Learning").doc(moduleRefId).set(
          {
            completedTill: 2,
            description: formData.description,
          },
          { merge: true }
        );
        setFormState(formState + 1);
      }
    } else if (formState == 3) {
      if (!highLights.length || fileToUpload === "")
        alert("please fill all field and press add button ");
      else {
        handleImgUpload();
        setFormState(formState + 1);
      }
    } else if (formState === 4) {
      if (!topics.length) alert("please fill all field and press add button ");
      else {
        db.collection("XOOG_Learning")
          .doc(moduleRefId)
          .set({
            topics: topics,
            completedTill: 4,
          }, { merge: true });
        setFormState(formState + 1);
        setProgress(0);
      }
    } else if (formState === 5) {
      if (
        formData.instructorName === "" ||
        formData.instructorDetails === "" ||
        fileToUpload === ""
      )
        alert("please fill all required fields");
      else {
        updateTutor();
        setFormState(formState + 1);
      }
    } else if (formState === 6) {
      if (!faqArray.length)
        alert("please fill all field and press add button ");
      else {
        db.collection("XOOG_Learning")
          .doc(moduleRefId)
          .set({
            FAQs: faqArray,
            completedTill: 6,
            isCompletelyCreated: "true"
          }, { merge: true });

        alert("Details added Successfully");
        setFormState(formState + 1);
      }
    }
  };
  const onCancel = async () => {
    if (formState === 1) {
      setFormData({
        ModuleName: "",
        quickDescription: "",
        courseDuration: "",
        orgName: "",
        websiteurl: "",
        enrollmentFee: "",
        typeOfCourse: "",
        description: "",
        highlight: "",
        topicHeading: "",
        topicDuration: "",
        topicSummary: "",
        instructorName: "",
        instructorDetails: "",
        faqQuestion: "",
        faqAnswer: "",
      })
    }
    else if (formState >= 5) {
      await db.collection("XOOG_Learning")
        .doc(moduleRefId).get().then((tut) => {
          console.log(tut.data())
          db.collection("XOOG_host").doc(tut.data().tutor).delete();
        })
      await db.collection("XOOG_Learning")
        .doc(moduleRefId).delete();
    }
    else {
      db.collection("XOOG_Learning")
        .doc(moduleRefId).delete();
    }

    alert("Course is not uploaded ")
    setFormState(1);
  }

  useEffect(() => {
    if (auth.currentUser) {
      if (match.state) {
        var temp = match.state;
        temp = temp * 1;
        setFormState(temp + 1)
        setModuleRefId(match.courseId)
      }
      setTutorId(auth.currentUser.uid);
      setAuthorised(null);

    } else setAuthorised(<LoginForm />);
  }, [auth.currentUser]);

  if (authorised) return authorised;
  return (
    <div className={styles.CreateEvent}>
      <h1 className={styles.header}>Course Setup</h1>
      <ul className={styles.formnav}>
        <li className={formState === 1 ? styles.active : styles.unactiveli}>1. Setup</li>
        <li className={formState === 2 ? styles.active : styles.unactiveli}>2. Course Details</li>
        <li className={formState === 3 ? styles.active : styles.unactiveli}>3. Course Highlights</li>
        <li className={formState === 4 ? styles.active : styles.unactiveli}>4. Add Topic</li>
        <li className={formState === 5 ? styles.active : styles.unactiveli}>5. Add Instructor</li>
        <li className={formState === 6 ? styles.active : styles.unactiveli}>6. Add FAQ</li>
      </ul>

      <div>
        {formState === 1 ? (
          <div className={styles.SetupForm}>
            <div className={styles.leftSetup}>
              <Box m={2}>
                <TextField
                  required
                  label="Course Name"
                  type="text"
                  name="ModuleName"
                  className={styles.textfield}
                  onChange={handleForm}
                />
              </Box>
              <Box m={2}>
                <TextField
                  required
                  label="quickDescription (under 100 character)"
                  type="text"
                  name="quickDescription"
                  className={styles.textfield}
                  inputProps={{
                    maxlength: 100,
                  }}
                  helperText={`${formData.quickDescription.length}/${100}`}
                  onChange={handleForm}
                />
              </Box>
              <div className={styles.orgSetup}>
                <Box m={2}>
                  <TextField
                    required
                    label="Organization Name"
                    type="text"
                    name="orgName"
                    className={styles.textfield}
                    onChange={handleForm}
                  />{" "}
                </Box>
                <Box m={2}>
                  <TextField
                    label="Website URL (if present)"
                    type="text"
                    name="websiteurl"
                    className={styles.textfield}
                    onChange={handleForm}
                  />
                </Box>
              </div>
              <div className={styles.priceSetup}>
                <Box m={2}>
                  <FormControl
                    component="fieldset"
                    style={{ dislpay: "inline" }}
                  >
                    <FormLabel component="legend">Type</FormLabel>
                    <RadioGroup
                      row
                      aria-label="Type"
                      name="row-radio-buttons-group"
                      onChange={handleRadioCourseType}
                    >
                      <FormControlLabel
                        value="Free"
                        control={<Radio />}
                        label="Free"
                      />
                      <FormControlLabel
                        value="Paid"
                        control={<Radio />}
                        label="Paid"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box m={2}>
                  <TextField
                    label="Price"
                    type="number"
                    name="enrollmentFee"
                    className={styles.textfield}
                    onChange={handleForm}
                  />
                </Box>
              </div>
            </div>
            <div className={styles.rightSetup}>
              <Box m={2}>
                <TextField
                  required
                  label="Average Duration"
                  type="number"
                  name="courseDuration"
                  className={styles.textfield}
                  onChange={handleForm}
                />
              </Box>
            </div>
          </div>
        ) : null}

        {formState === 2 ? (
          <div>
            {/* <Box m={2}> */}
            <h3>Course information in Detail</h3>
            <p style={{ margin: "5px 0px" }}>Detialed Information for the course in (200-400 Characters)</p>
            <br />
            <TextField
              required
              type="text"
              name="description"
              multiline
              rows={6}
              rowsMax={10}
              className={styles.textfield}
              inputProps={{
                maxlength: 100,
              }}
              helperText={`${formData.description.length}/${400}`}
              onChange={handleForm}
            />
            {/* </Box> */}
          </div>
        ) : null}
        {formState === 3 ? (

          <div className={styles.courseHighlights}>

            <div className={styles.courseHighlightsContent}>
              <div>
                <h3>Add course HighLights</h3>

                <p className={styles.paracourseHL}>
                  QuickDescription points of what the student will learn and
                  what the student can expect from the course. It can include
                  topics, perks like certification etc.{" "}
                </p>
                <TextField
                  required
                  type="text"
                  value={formData.highlight}
                  name="highlight"
                  label="Add HighLight"
                  className={styles.textfield}
                  onChange={handleForm}
                />

                <Button onClick={() => handleHighlights()}>
                  + Add course Highlights
                </Button>

                <div className={styles.highLights}>
                  <p>List of HighLights To be Uploaded</p>
                  <div className={styles.highLightsList}>
                    <ol>
                      {highLights.map((value, index) => {
                        return (
                          <li>{value}</li>
                        )
                      })
                      }
                    </ol>
                  </div>
                </div>



              </div>
              <div>
                <h3 className={styles.paracourseHL}>Upload a Poster for the Course</h3>
                <p className={styles.paracourseHL}>A Poster with Visual representation of the course</p>

                {progress != 0 ? <progress value={progress} max="100" /> : null}
                <input type="file" className={styles.fileInput} onChange={handleImageFile} />
                {/* <Button onClick={() => handleImgUpload()} variant="contained">Upload</Button> */}
                <p className={styles.paralinescourseHL}>Do you want us to help with poster?</p>
                <p className={styles.paralinescourseHL}> Contact us on WhatsApp!</p>
              </div>
            </div>



            {/* </Box> */}
          </div>
        ) : null}
        {formState === 4 ? (
          <div className={styles.addTopic}>
            <div className={styles.addTopicForm}>
              <Box m={3}>
                <p>
                  Detailed Information on the Topics they will learn during the
                  course
                </p>
              </Box>{" "}
              <Box m={3}>
                <TextField
                  required
                  label="Add Topic Heading"
                  type="text"
                  value={formData.topicHeading}
                  name="topicHeading"
                  className={styles.textfield}
                  onChange={handleForm}
                />
              </Box>{" "}
              <Box m={3}>
                <TextField
                  required
                  label="Topic Duration"
                  value={formData.topicDuration}
                  type="number"
                  name="topicDuration"
                  className={styles.textfield}
                  onChange={handleForm}
                />
              </Box>{" "}
              <Box m={3}>
                <TextField
                  required
                  label="Summary"
                  value={formData.topicSummary}
                  type="text"
                  name="topicSummary"
                  className={styles.textfield}
                  onChange={handleForm}
                />
              </Box>
              <Box m={3}>
                <Button onClick={() => handleTopics()}>+ Add Topic</Button>
              </Box>
            </div>

            <div className={styles.addedTopic} id="addedTopic">
              <p>List of Added Topics To be Uploaded</p>
              <div className={styles.addedTopicList} id="addedTopic">
                <ul>
                  {topics.map((data, index) => {
                    return (
                      <li>
                        <li>Topic {index + 1}</li>
                        <ul>
                          <li>Title : {data.title}</li>
                          <li>Duration : {data.duration}</li>
                          <li>Description : {data.description}</li>
                        </ul>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        ) : null}
        {formState === 5 ? (
          <div className={styles.addInstructor}>
            <div className={styles.addInstructorUpper}>
              <div>
                <TextField
                  required
                  label="Instructor Name"
                  type="text"
                  name="instructorName"
                  className={styles.textfield}
                  onChange={handleForm}
                />
              </div>
              <div>
                <p style={{ fontSize: "25px" }}>Tutor Profile</p>
                {progress != 0 ? (
                  <progress value={progress} max="100" />
                ) : null}
                <input type="file" onChange={handleImageFile} />
              </div>
            </div>

            <div style={{ marginTop: "30px" }}>
              <TextField
                required
                label="About the Instructor"
                type="text"
                name="instructorDetails"
                className={styles.textfield}
                onChange={handleForm}
              />
            </div>
          </div>
        ) : null}
        {formState === 6 ? (
          <div className={styles.faq}>
            <div className={styles.faqForm}>
              <Box m={3}>
                <TextField
                  required
                  label="Question"
                  value={formData.faqQuestion}
                  type="text"
                  name="faqQuestion"
                  className={styles.textfield}
                  onChange={handleForm}
                />
              </Box>
              <Box m={3}>
                <TextField
                  required
                  label="Answer"
                  value={formData.faqAnswer}
                  type="text"
                  name="faqAnswer"
                  className={styles.textfield}
                  onChange={handleForm}
                />
              </Box>

              <Box m={3}>
                <Button onClick={() => handleFAQs()}>+ Add FAQ</Button>
              </Box>
            </div>
            <div className={styles.addedFAQs}>
              <p>List to FAQs to upload</p>
              <div className={styles.listOfFAQs}>
                <ul>
                  {faqArray.map((data, index) => {
                    return (
                      <li>
                        <p>Question {index + 1}). {data.question}</p>
                        <p>Answer : {data.answer}</p>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        ) : null}

        {formState > 6 ? (
          <div>
            <Box m={5}>
              <h1>Details Added Successfully !!</h1>
              <button className={styles.submitbutton} onClick={() => setFormState(1)}>Add New</button>
            </Box>
          </div>

        ) : null}
      </div>
      {formState < 7 ? (
        <div className={styles.buttondiv}>
          <button className={styles.submitbutton} onClick={() => SaveForm()}>
            {formState == 1 ? <p>save</p> :
              (formState != 6 ? <p>Save & Continue</p> : <p>Finish</p>)}
          </button>
          <Button onClick={() => onCancel()}>Cancel</Button>
        </div>
      ) : null}
    </div>
  );
};

export default CreateEvent;

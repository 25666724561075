import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import styles from './CardsDisplay.module.css';

const CardsDisplay = ({ cardsContainerList }) => {
  let cardsDisplay = [];
   if (cardsContainerList.length != 0) {
    cardsContainerList.forEach((container) => {
      cardsDisplay.push(
        <>
          <div className={styles.cardsContainerHeader}>
            <h3><b>{container.header} {container.coloredText ? (<span style={{ color: container.coloredText.color }}>{container.coloredText.text}</span>) : ""}</b></h3>
          </div>
          <div className={container.xScrollable && styles.xScrollableContainer}>
            {
              container.cardsList.length !== 0 ? container.cardsList : <p className={styles.placeholderText}>{container.fallbackText}</p>
            }
          </div>
        </>
      );
    });
  } else {
    cardsDisplay.push(
      <div style={{ textAlign: "center" }}>
        <p className={styles.placeholderText}>Loading...</p>
      </div>
    );
  }

  return (
    <div className={styles.allCardsContainer}>
      {cardsDisplay}
    </div>
  );
};

// CardsDisplay.propTypes = {};

// CardsDisplay.defaultProps = {};

export default CardsDisplay;

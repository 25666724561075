'use strict'

const _XOOG_ENUMS = {
  APP_MODES: {
    DEV: "TEST",
    DEPLOY: "XOOG"
  },
  EVENT_STATUS: {
    DEFAULT: 0,
    STARTS_TODAY: 1,
    ONGOING: 2,
    ENDED: 3
  },
  REG_STATUS: {
    OPEN: true,
    CLOSED: false
  }
};

Object.freeze(_XOOG_ENUMS);

export default _XOOG_ENUMS;

import React, { useEffect, useState } from "react";
import styles from "./LearningModuleCard.module.css";

// import _XOOG_ENUMS from "../../Enums/Xoog_Enums.js";

// import RatingStars from "../RatingStars/RatingStars";

// props = {history, moduleObj, tutorObj, showTutor}
const LearningModuleCard = (props) => {
  
  const moduleObj = props.moduleObj;
  // const tutorObj = props.tutorObj;

  // const rating = {
  //   value: tutorObj.courseRating[moduleObj.id].avgRating,
  //   noOfRatings: tutorObj.courseRating[moduleObj.id].noOfRatings,
  // }

  // const goToTutorProfile = (e) => {
  //   e.stopPropagation();
  //   props.history.push("/tutorProfile/" + moduleObj.data.tutor);
  // }

  return (
    <div className={styles.moduleCard} >
      <h4 className={styles.moduleName} title={moduleObj.data.ModuleName}>{moduleObj.data.ModuleName}</h4>
      <img className={styles.moduleCardImage} src={moduleObj.data.courseImgLink}></img>
      <div className={styles.moduleCardDescription}>
        {moduleObj.data.quickDescription}
      </div>
      {/* {
        props.showTutor ? (
          <div className={styles.tutorPreviewContainer}>
            <div className={styles.tutorName}>{tutorObj.HostName}</div>
            <div className={styles.tutorImgContainer} onClick={(e) => { goToTutorProfile(e) }}>
              <img className={styles.tutorImg} src={tutorObj.profileImgLink}></img>
            </div>
            <div className={styles.tutorRating}>
              <RatingStars size="small" rating={rating} isAvg={true} readOnly={true}/>
            </div>
          </div>
        ) : <></>
      } */}
    </div>
  );
};

export default LearningModuleCard;

import React, { useEffect, useState } from "react";
import _XOOG_BUILD_VARS from "../../../../DataStructures/Build_Vars";
import { db } from "../../../../firebase";
import Styles from './RequestCard.module.css';

let expand=false;

const RequestCard = (props)=>{

    const [studentEmail, setStudentEmail]=useState("");
    const [studentPhone,setStudentPhone]=useState("");

    const onClickDropDownStatus=(event)=>{
        event.stopPropagation();
    }

    const studentId=props.studentRequestData.studentId;

    const getStudentDetails = ()=>{
        db.collection(_XOOG_BUILD_VARS.COLLECTION.USERS).doc(studentId).get().then((doc)=>{

            if(doc!=null){
                setStudentPhone(doc.data().phone);
                setStudentEmail(doc.data().email);
            }

        })
    }

  
    useEffect(()=>{
        expand=false;
    },[]);
    
    if(studentEmail==""||studentPhone==""){
        getStudentDetails();
    
    }

  //  const courseId=props.courseId;
    const studentName = props.studentRequestData.name;
   // const studentEmail = "karishma@xoog.info";
   
  //  const studentPhone = "+91 9677453733";
    const status = props.studentRequestData.status;
  //  const courseName=props.courseName;
    const timeofEnquiry = props.studentRequestData.timeofEnquiry;
   // const studentId = props.studentRequestData.studentId;

   const courseName=props.studentRequestData.courseName;
    

 

    var options = {  month: 'short', day: 'numeric',hour: 'numeric', minute : 'numeric'};
    const enquiryTime = new Date(timeofEnquiry * 1000).toLocaleDateString("en-US",options);

    const onClickOnBoardButton = (event) =>{
        event.stopPropagation();

    }

   
 

  
    
   

    return(

        
              
        <div className={Styles.cardPreview} >
            
            <div className={Styles.profileColumn}>
                <div>
                    {studentName}   
                </div>
                <div>
                    {studentPhone}   
                </div>
                <div>
                    {studentEmail}   
                </div>
               
            </div>

            <div className={Styles.singleColumn}>
               
               {enquiryTime}
           
           </div>

           <div className={Styles.singleColumn}>
               
               {courseName}
           
           </div>
   
         { /*  <div className={Styles.singleColumn}>
                <select name="status" className={Styles.cardButton} onClick={onClickDropDownStatus}>
            
                    <option value="NewStudent">New Student</option>
                    <option value="Contacted">Contacted</option>
                    <option value="NotInterested">Not Interested</option>
   
                </select>  
            </div >
    
          
    
            <button onClick={onClickOnBoardButton} className={Styles.cardButton}>
                Onboard Student
            </button>
    */}
            </div>
    
       
       
      

       
    );

}

export default RequestCard;
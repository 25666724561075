
const TAG='LoginReducer ';

const initialState = {
    valid:false
}

const isLoginReducer = (state=initialState,action)=>{
 
    switch(action.type){
        case 'SET_IS_LOGIN_PAGE':
           
           return{
            valid:action.payload
           };
         
        default:
            console.log('default switch '+state);
            return state;
    }

}

export default isLoginReducer;
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { db } from '../../firebase';
import './Table.css'
import TableRow from './TableRow';
function Table({number,name,allParticipants}) {

    const [batchParticipantDetails,setBatchParticipantDetails]=useState([]);
    let {id,category}=useParams();
    
    const [finalScore,setFinalScore]=useState();
    const [check1,setCheck1]=useState('Ok');
    const [check2,setCheck2]=useState('Ok');
    const [check3,setCheck3]=useState('Ok');
    const [check4,setCheck4]=useState('Ok');
    const [check5,setCheck5]=useState('Ok');

    useEffect(async()=>{

        if(allParticipants===true){
            console.log('Table','All Participants');
            await db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_participants').onSnapshot((snapshot)=>{
                const items=[];
                snapshot.forEach((doc)=>{
                    items.push({data:doc.data(),id:doc.id});
                })
                console.log(items,'items');
                setBatchParticipantDetails(items);
            })
        }else{
        const numberArr=number.split(" ");
        const batch=parseInt(numberArr[1]);
        console.log('Table','Batch',batch)
        await db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_participants').where('Batch','==',`B${batch}`).onSnapshot((snapshot)=>{
            const items=[];
            snapshot.forEach((doc)=>{
                items.push({data:doc.data(),id:doc.id});
            })
            console.log(items,'items');
            setBatchParticipantDetails(items);
        })
    }
       
    },[number])
    
   
        // time.sort((a,b)=>{
        //     return a.time-b.time
        // })
        // let dnf=0,totalTime=0;
        // time.forEach((object)=>{
        //     if(object.isApproved==='DNF'){
        //         dnf++;
        //         if(dnf===2){
        //             db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_participants').doc(dataid).update({A05:"No results"});
        //             return;
        //         }
        //     }else if(object.isApproved==='+2'){
        //         totalTime+=(object.time+2);
        //     }else{
        //         totalTime+=(object.time);
        //     }
        // })
        // console.log(totalTime);
    

    

    return (
        <div className='participantTable'>
                <table>
                <thead>
                    <tr>
                    <th>S.No</th>
                    <th>Student</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>solve 1</th>
                    <th>approve</th>
                    <th>solve 2</th>
                    <th>approve</th>
                    <th>solve 3</th>
                    <th>approve</th>
                    <th>solve 4</th>
                    <th>approve</th>
                    <th>solve 5</th>
                    <th>approve</th>
                    <th>Calculate Result</th>
                    <th>Final Score</th>
                    </tr>
                </thead>
                {
                    batchParticipantDetails &&
                batchParticipantDetails.map((details,key)=>{
                     console.log(details,'map');
                    return <TableRow key={key} details={details} name={name}/>
                    
                    })
                }
                </table>
            </div>
    )

}
export default Table

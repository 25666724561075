import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { db } from '../../firebase';
import './HostDetails.css'
function HostDetails({batchArr,batchNum,eventName,bnum}) {

   console.log("this is bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbnum :",bnum)
    const [getBatch,setGetBatch]=useState(`Batch ${bnum}`);
    const [videoURL,setVideoURL]=useState('');
    const [scramble1,setScramble1]=useState('');
    const [scramble2,setScramble2]=useState('');
    const [scramble3,setScramble3]=useState('');
    const [scramble4,setScramble4]=useState('');
    const [scramble5,setScramble5]=useState('');
    let{id,category}=useParams();
    const [hostDetails,setHostDetails]=useState({});
    const [editOn1,setEditOn1]=useState(true);
    const [editOn2,setEditOn2]=useState(true);
    const [editOn3,setEditOn3]=useState(true);
    const [editOn4,setEditOn4]=useState(true);
    const [editOn5,setEditOn5]=useState(true);
    const [editOn6,setEditOn6]=useState(true);

   const shortBatch=(batch)=>{
      let array=batch.split(" ");
      batch='B'+array[1];
      return batch;
   }

   useEffect(()=>{
      let batch=shortBatch(getBatch)
      db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_Data').doc(batch).onSnapshot(snapshot=>{
         console.log("YOOO",snapshot.data())
      })
      
   },[])


   // useEffect(()=>{
   //    db.collection('XOOG_leaderboard').doc(`${id}`).get().then((item)=>{
   //       setEventName(item.data().EventName);
   //       getEventName(item.data().EventName);
   //    })
   // },[])

    useEffect(async()=>{
      batchNum(getBatch);  
       let batch=shortBatch(getBatch);
      // console.log("hello>>>>",batchNum);
      await db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_Data').doc(batch).onSnapshot(snapshot=>{
         if(!snapshot.exists){
            db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_Data').doc(batch).set({ScrambleLink:"",S1:"",S2:"",S3:"",S4:"",S5:"",batch:getBatch});
            setHostDetails({ScrambleLink:"",S1:"",S2:"",S3:"",S4:"",S5:""})
         }else{
            console.log("YOOOO@",snapshot.data())
            setHostDetails(snapshot.data())
            console.log("HOST details",hostDetails)
         }
      })
    },[getBatch])
    

    const updateVideoLink=()=>{
       let batch=shortBatch(getBatch);
       db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_Data').doc(batch).update({ScrambleLink:videoURL});
       setVideoURL('');
       setEditOn1(true);
    }
    const scramble1Upload=()=>{
      let batch=shortBatch(getBatch);
      db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_Data').doc(batch).update({S1:scramble1});
      setScramble1('');
      setEditOn2(true);
    }
    const scramble2Upload=()=>{
      let batch=shortBatch(getBatch);
      db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_Data').doc(batch).update({S2:scramble2});
      setScramble2('');
      setEditOn3(true);
    }
    const scramble3Upload=()=>{
      let batch=shortBatch(getBatch);
      db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_Data').doc(batch).update({S3:scramble3});
      setScramble3('');
      setEditOn4(true);
    }
    const scramble4Upload=()=>{
      let batch=shortBatch(getBatch);
      db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_Data').doc(batch).update({S4:scramble4});
      setScramble4('');
      setEditOn5(true);
    }
    const scramble5Upload=()=>{
      let batch=shortBatch(getBatch);
      db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_Data').doc(batch).update({S5:scramble5});
      setScramble5('');
      setEditOn6(true);
    }

    return (
        <div className='batchDetails'>
               <h1 className='batchHeading'>{eventName}</h1>
                <div className='selectBatch'>
                   
               <label forhtml="batchNo">Choose your batch number</label>
               <select id='batchNo' value={getBatch} onChange={(e)=>{setGetBatch(e.target.value);}}>
                    {
                        batchArr.map(batch=>(
                            <option value={batch} >{batch}</option>
                        ))
                    }
               </select> 
               </div>
            <table className='detailTable' >
                {/* <div className='scramble'> */}
                <tr>
                      <td><label forhtml="video">Video URL: </label></td>
                      {
                         hostDetails.ScrambleLink && editOn1?(
                         <>
                         <td><p>{hostDetails.ScrambleLink}</p></td>
                         <td><button style={{width:'60px'}} onClick={()=>{
                            setEditOn1(false)
                         }}>Edit</button></td>
                         </>):(
                            <>
                              <td><input id='video' type='text' value={videoURL} onChange={(e)=>{
                              setVideoURL(e.target.value);
                           }} /></td>
                           <td><button onClick={updateVideoLink}>Go</button></td>
                            </>
                         )
                      }
                            
                      
                </tr>  

                <tr>
                    <td><label forhtml="scramble1">Scramble 1: </label></td>
                    {
                       hostDetails.S1 && editOn2?(
                       <>
                       <td><p>{hostDetails.S1}</p></td>
                       <td><button style={{width:'60px'}} onClick={()=>{
                          setEditOn2(false)
                       }}>Edit</button></td>
                       </>):(
                          <>
                           <td><input id="scramble1" type='text' value={scramble1} onChange={(e)=>{
                       setScramble1(e.target.value);
                    }}/></td>
                    <td><button onClick={scramble1Upload}>Go</button></td>
                          </>
                       )
                    }
                   
                 </tr>
                 <tr>
                    <td><label forhtml="scramble2">Scramble 2: </label></td>
                    {
      
                       hostDetails.S2 && editOn3?(
                          <>
                          <td><p>{hostDetails.S2}</p></td>
                       <td><button style={{width:'60px'}} onClick={()=>{
                          setEditOn3(false)
                       }}>Edit</button></td>
                       </>):(
                          <>
                          <td><input id="scramble2" type='text' value={scramble2} onChange={(e)=>{
                       setScramble2(e.target.value)
                    }}/></td>
                    <td><button onClick={scramble2Upload}>Go</button></td>
                          </>
                       )
                    }
                    
                 </tr> 
                 <tr>
                    <td> <label forhtml="scramble3">Scramble 3: </label></td>
                    {
                       hostDetails.S3 && editOn4?(
                        <>
                       <td><p>{hostDetails.S3}</p></td>
                       <td><button style={{width:'60px'}} onClick={()=>{
                          setEditOn4(false)
                       }}>Edit</button></td>
                       </>
                       ):(
                          <>
                          <td><input id="scramble3" type='text' value={scramble3} onChange={(e)=>{
                       setScramble3(e.target.value)
                    }}/></td>
                    <td><button onClick={scramble3Upload}>Go</button></td>
                          </>
                       )
                    }
                    
                 </tr> 
                 <tr>
                    <td><label forhtml="scramble4">Scramble 4: </label></td>
                    {
                       hostDetails.S4 && editOn5?(<>
                       <td><p>{hostDetails.S4}</p></td>
                       <td><button style={{width:'60px'}} onClick={()=>{
                          setEditOn5(false)
                       }}>Edit</button></td>
                       </>):(
                          <>
                          <td><input id="scramble4" type='text' value={scramble4} onChange={(e)=>{
                       setScramble4(e.target.value)
                    }}/></td>
                    <td><button onClick={scramble4Upload}>Go</button></td>
                          </>
                       )
                    }
                    
                 </tr>
                 <tr>
                    <td><label forhtml="scramble5">Scramble 5: </label></td>
                    {
                       hostDetails.S5 && editOn6?(
                       <>
                       <td><p>{hostDetails.S5}</p></td>
                       <td><button style={{width:'60px'}} onClick={()=>{
                          setEditOn6(false);
                       }}>Edit</button></td>
                       </>
                       ):(
                          <>
                          <td><input id="scramble5" type='text' value={scramble5} onChange={(e)=>{
                           setScramble5(e.target.value)
                           }}/></td>
                           <td><button onClick={scramble5Upload}>Go</button></td>
                          </>
                       )
                    }
                    
                 </tr>
            </table>
            </div>
    )
}

export default HostDetails
'use strict'

import _XOOG_CONSTS from "./Consts/Xoog_Consts";

const _XOOG_BUILD_VARS = {
    COLLECTION: {
        EVENTS: _XOOG_CONSTS.COLLECTION_TYPES[_XOOG_CONSTS.APP_MODE] + "_" + _XOOG_CONSTS.COLLECTION_NAMES.EVENTS,
        USERS: _XOOG_CONSTS.COLLECTION_TYPES[_XOOG_CONSTS.APP_MODE] + "_" + _XOOG_CONSTS.COLLECTION_NAMES.USERS,
        HOSTS: _XOOG_CONSTS.COLLECTION_TYPES[_XOOG_CONSTS.APP_MODE] + "_" + _XOOG_CONSTS.COLLECTION_NAMES.HOSTS,
        LEARNING_MODULES: _XOOG_CONSTS.COLLECTION_TYPES[_XOOG_CONSTS.APP_MODE] + "_" + _XOOG_CONSTS.COLLECTION_NAMES.LEARNING_MODULES,
    },

    COLLECTION_LEARN_FIELDS: {
        COURSE_NAME: "ModuleName",
        TUTOR : "tutor",
        STUDENT_COLLECTION: "Students"
    },

    COLLECTION_LEARN_STUDENT_FIELDS: {
        STUDENT_NAME:"Name",
        
    },

    COLLECTION_HOST_FIELDS:{
        COURSES_TUTORED:"coursesTutored"
    }
};

Object.freeze(_XOOG_BUILD_VARS);

export default _XOOG_BUILD_VARS;

import React, { useEffect, useState } from 'react'
import { db } from '../../firebase';
import Button from '../Button/Button'
import './EventComponent.css'
function EventComponent({number,EventState,numberInc,onClick,changePage}) {
    const id=EventState.id;
    const [odd,setOdd]=useState(true);
    const [show,setShow]=useState(false);
    useEffect(()=>{
      const checkTime=()=>{
        var startTime=new Date(EventState.startTime);
        var endTime=new Date(EventState.endTime);
        var currentDateTime =new Date();
        if(currentDateTime>=startTime && currentDateTime<=endTime){
            setShow(true);
            numberInc()
        }
        console.log(startTime,endTime,"eventComponent checking YOOOOOOOOOOOOO");
      }
      checkTime();
    },[])

    const updateShow=async()=>{
      console.log('update show is running');
      // console.log(EventState)
      const allParticipantData=[]
    for(var i=0;i<EventState.puzzle.length;i++){
       console.log(EventState.puzzle[i].eventName)
       var temp=EventState.puzzle[i].eventName;
      db.collection('XOOG_leaderboard').doc(id).collection(`${temp}_participants`).get().then(response=>{
         response.forEach((participantDetails)=>{
           const participant=participantDetails.data();
           if(participant.A05==='Not completed' || participant.A05==='No results'){
             participant.A05=999999;
           }
           console.log(participant)
           allParticipantData.push(participant)
         })
         console.log("BeforeSorting",id,allParticipantData)
         allParticipantData.sort((a,b)=>{
           return a.A05-b.A05
         })
         console.log(allParticipantData)
         var rank=0;
         for(var i=0;i<allParticipantData.length;i++){
           console.log("Inside Loop Event Leaderboard",allParticipantData[i])
            db.collection('XOOG_leaderboard').doc(id).collection(`${temp}_participants`).doc(allParticipantData[i]._Id).update({rank:rank+1})
            db.collection('XOOG_users').doc(allParticipantData[i]._Id).collection('participate').doc(`${id}_${temp}`).update({rank:rank+1});
            rank++
         }
       });

       db.collection('XOOG_leaderboard').doc('Overall_Leaderboard').collection(`${temp}_participants`).get().then(response=>{
        response.forEach((participantDetails)=>{
          const participant=participantDetails.data();
          if(participant.A05==='Not completed' || participant.A05==='No results'){
            participant.A05=999999;
          }
          console.log(participant)
          allParticipantData.push(participant)
        })
        console.log("BeforeSorting",id,allParticipantData)
        allParticipantData.sort((a,b)=>{
          return a.A05-b.A05
        })
        console.log('updating overall leaderboard');
        console.log(allParticipantData)
        var rank=0;
        for(var i=0;i<allParticipantData.length;i++){
          console.log("Inside Loop overall leaderboard",allParticipantData[i])
           db.collection('XOOG_leaderboard').doc('Overall_Leaderboard').collection(`${temp}_participants`).doc(allParticipantData[i]._Id).update({rank:rank+1,Name:allParticipantData[i].Name})
           rank++
        }
      });


     }
      db.collection('XOOG_leaderboard').doc(id).update({show:true})
    }
    if(show){
    return (
        
        <div className='eventComponent'>
        
            <div className='eventComponentName'>
                
            <h1 className={number%2===0?'even':'odd'}>{EventState.EventName}</h1>
            <button className={number%2===0?'evenButton':'oddButton'} onClick={updateShow}>Compute</button>
            </div>
     
          
          <div className='eventTypeButtons'>
          {
              EventState.puzzle.map(object=>{
                    if(number%2===0){
                  return <Button order='even' changePage={changePage} object={object} onClick={onClick} eventName={EventState.EventName} eventId={EventState.id}/>
                    }
                  else{
                  return <Button order='odd' object={object} changePage={changePage} onClick={onClick} eventName={EventState.EventName} eventId={EventState.id}/>
                  }
              })
          }
          </div>
          
        </div>
    )
  }else{
    return null;
  }
}

export default EventComponent

import React from 'react'
import Spinner from "react-spinkit";
import './loader.css'
function Loader() {
    return (
        <div className='loader'>
            <Spinner
            className='spinner'
        name="cube-grid"
        color="gray"
        fadeIn="none"
        />
        </div>
    )
}

export default Loader

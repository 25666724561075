'use strict'

import _XOOG_ENUMS from "../Enums/Xoog_Enums";

const _XOOG_CONSTS = {
  APP_MODE: _XOOG_ENUMS.APP_MODES.DEPLOY,
  COLLECTION_TYPES: {
    TEST: "TEST",
    XOOG: "XOOG"
  },
  COLLECTION_NAMES: {
    EVENTS: "leaderboard",
    USERS: "users",
    HOSTS: "host",
    LEARNING_MODULES: "Learning",
  },
  FACULTY: {
    rubiks: "Rubik's",
    art: "Art"
  }
};

Object.freeze(_XOOG_CONSTS);

export default _XOOG_CONSTS;

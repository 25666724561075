import React, { useState } from 'react'
import { useParams } from 'react-router';
import { db } from '../../firebase';

function TableRow({details,name}) {
    let {id,category}=useParams()
    console.log(id,category)
    const [check1,setCheck1]=useState('Ok');
    const [check2,setCheck2]=useState('Ok');
    const [check3,setCheck3]=useState('Ok');
    const [check4,setCheck4]=useState('Ok');
    const [check5,setCheck5]=useState('Ok');


    const calculateResult= async(data,dataid)=>{      
        console.log("this is  data .is",dataid)
        const time=[];
        time.push({time:data.solveTime1.time,check:check1})
        time.push({time:data.solveTime2.time,check:check2})
        time.push({time:data.solveTime3.time,check:check3})
        time.push({time:data.solveTime4.time,check:check4})
        time.push({time:data.solveTime5.time,check:check5})
        var solved=0,dnf=0;

        time.forEach((object)=>{
            if((object.time!==0)||(object.time===0 && object.check==='DNF')){
                solved++;
                if(object.check==='DNF'){
                    object.time=9999;
                    dnf++;
                }
            }
        })

        let Single = Math.min(data.solveTime1.time,
            data.solveTime2.time,
            data.solveTime3.time,
            data.solveTime4.time,
            data.solveTime5.time)

        if(dnf>=2){
            db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_participants').doc(dataid).update({A05:"No results",'solveTime1.isApproved':check1,'solveTime2.isApproved':check2,'solveTime3.isApproved':check3,'solveTime4.isApproved':check4,'solveTime5.isApproved':check5});
            // Update for Xoog users

            db.collection('XOOG_users').doc(data._Id).collection('participate').doc(`${id}_${category}`).update({
                A05:"No results",
                Puzzle:category,
                EventName:id,
                Solve1:data.solveTime1.time,
                Solve2:data.solveTime2.time,
                Solve3:data.solveTime3.time,
                Solve4:data.solveTime4.time,
                Solve5:data.solveTime5.time,
                Single:Single
            })

            //Overall leaderbnoard
            // db.collection('XOOG_leaderboard').doc('Overall_Leaderboard').collection(`${category}_participants`).doc(data._Id).update({A05:"No results"})

        }else if(solved===5){
                time.sort((a,b)=>{
                    return a.time-b.time;
                 })
                time.pop();
                time.shift();
                let totalTime=0;
                time.forEach((object)=>{
                    if(object.check==='+2')
                        totalTime+=2;
                        totalTime+=object.time;
                    })
                totalTime/=time.length;
                totalTime=totalTime.toFixed(2);
                // for caclculating single
                 console.log("this is single",Single) 
                 console.log("this is single",category) 
                 console.log("this is single",dataid) 
                 console.log("this is single",id) 
                db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_participants').doc(dataid).update({A05:parseFloat(totalTime),Single:Single,'solveTime1.isApproved':check1,'solveTime2.isApproved':check2,'solveTime3.isApproved':check3,'solveTime4.isApproved':check4,'solveTime5.isApproved':check5});
                db.collection('XOOG_leaderboard').doc("Overall_Leaderboard").collection(category+'_participants').doc(dataid).update({A05:parseFloat(totalTime),Single:Single});
                const competitionName=await db.collection('XOOG_leaderboard').doc(`${id}`).get()
                   // Update Xoog USers
                    console.log("this is in host : ",id ,category)
                    db.collection('XOOG_users').doc(data._Id).collection('participate').doc(`${id}_${category}`).update({
                        A05:parseFloat(totalTime),
                        Puzzle:category,
                        EventName:competitionName.data().EventName, //actually this field is for competition name, we have to look about it ,not final because haven't find competition collection in this whole project
                        Solve1:data.solveTime1.time,
                        Solve2:data.solveTime2.time,
                        Solve3:data.solveTime3.time,
                        Solve4:data.solveTime4.time,
                        Solve5:data.solveTime5.time,
                        Single:Single
                    })

                    //Overall leaderboard
                    // db.collection('XOOG_leaderboard').doc('Overall_Leaderboard').collection(`${category}_participants`).doc(data._Id).update({A05:parseFloat(totalTime)})

                    console.log(time);
                }else{
                    db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_participants').doc(dataid).update({A05:"Not completed",'solveTime1.isApproved':check1,'solveTime2.isApproved':check2,'solveTime3.isApproved':check3,'solveTime4.isApproved':check4,'solveTime5.isApproved':check5});
                    // Update Xoog Users
                    
                    db.collection('XOOG_users').doc(data._Id).collection('participate').doc(`${id}_${category}`).update({
                        A05:"Not completed",
                        Puzzle:category,
                        EventName:id,
                        Solve1:data.solveTime1.time,
                        Solve2:data.solveTime2.time,
                        Solve3:data.solveTime3.time,
                        Solve4:data.solveTime4.time,
                        Solve5:data.solveTime5.time,
                        Single:Single
                    })

                    //overall Leaderboard
                // db.collection('XOOG_leaderboard').doc('Overall_Leaderboard').collection(`${category}_participants`).doc(data._Id).update({A05:"Not completed"})

                }


    }
    return (
        <tr>
            <td></td>
                        <td>{details.data.Name}</td>
                        <td>{details.data.Phone}</td>
                        <td>{details.data.Email}</td>
                        {details.data.solveTime1&&
                        <>
                         <td>{details.data.solveTime1.time}</td>
                        <td>
                            <div>
                            {/* <p>{details.data.solveTime1.isApproved}</p> */}
                            {/* <p>{check1}</p> */}
                                <select value={check1} onChange={(e)=>{
                                    setCheck1(e.target.value);
                                }}>
                                    <option value="Ok">Ok</option>
                                    <option value="+2">+2</option>
                                    <option value="DNF">DNF</option>
                                </select>
                                {/* <button onClick={()=>{
                                    db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_participants').doc(details.id).update({'solveTime1.isApproved':check1})
                                }}>Click</button> */}
                            </div>
                        </td>



                        <td>{details.data.solveTime2.time}</td>
                        {/* <td>{details.data.solveTime2.isApproved}</td> */}
                        <td>
                        <div>
                        {/* <p>{details.data.solveTime2.isApproved}</p> */}

                                <select value={check2} onChange={(e)=>{
                                    setCheck2(e.target.value);
                                }}>
                                    <option value="Ok">Ok</option>
                                    <option value="+2">+2</option>
                                    <option value="DNF">DNF</option>
                                </select>
                                {/* <button onClick={()=>{
                                    db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_participants').doc(details.id).update({'solveTime2.isApproved':check2})
                                }}>Click</button> */}
                            </div>
                        </td>




                        <td>{details.data.solveTime3.time}</td>
                        {/* <td>{details.data.solveTime3.isApproved}</td> */}
                        <td>
                        <div>
                        {/* <p>{details.data.solveTime3.isApproved}</p> */}

                                <select value={check3} onChange={(e)=>{
                                    setCheck3(e.target.value);
                                }}>
                                    <option value="Ok">Ok</option>
                                    <option value="+2">+2</option>
                                    <option value="DNF">DNF</option>
                                </select>
                                {/* <button onClick={()=>{
                                    db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_participants').doc(details.id).update({'solveTime3.isApproved':check3})
                                }}>Click</button> */}
                            </div>
                        </td>



                        <td>{details.data.solveTime4.time}</td>
                        {/* <td>{details.data.solveTime4.isApproved}</td> */}
                        <td>
                        <div>
                        {/* <p>{details.data.solveTime4.isApproved}</p> */}

                                <select value={check4} onChange={(e)=>{
                                    setCheck4(e.target.value);
                                }}>
                                    <option value="Ok">Ok</option>
                                    <option value="+2">+2</option>
                                    <option value="DNF">DNF</option>
                                </select>
                                {/* <button onClick={()=>{
                                    db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_participants').doc(details.id).update({'solveTime4.isApproved':check4})
                                }}>Click</button> */}
                            </div>
                        </td>



                        <td>{details.data.solveTime5.time}</td> 
                        {/* <td>{details.data.solveTime5.isApproved}</td> */}
                        <td>
                        <div>
                        {/* <p>{details.data.solveTime5.isApproved}</p> */}

                                <select value={check5} onChange={(e)=>{
                                    setCheck5(e.target.value);
                                }}>
                                    <option value="Ok">Ok</option>
                                    <option value="+2">+2</option>
                                    <option value="DNF">DNF</option>
                                </select>
                                {/* <button onClick={()=>{
                                    db.collection('XOOG_leaderboard').doc(`${id}`).collection(category+'_participants').doc(details.id).update({'solveTime5.isApproved':check5})
                                }}>Click</button> */}
                            </div>
                        </td>
                        </>
                        }
                       
                        <td className='calculateCell'><button onClick={()=>{
                            calculateResult(details.data,details.id);
                        }}>Calculate</button></td>
                        <td>{details.data.A05}</td>
        </tr>
    )
}

export default TableRow

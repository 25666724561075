import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { db,auth } from '../../firebase';
import {styles} from './Login.module.css';
import { useDispatch} from "react-redux";
import { setAuth } from "../../react-redux/action/action";
import Logo from '../../images/xoog.svg'

import { FiUser } from "react-icons/fi";

function Login() {

    const dispatcher = useDispatch();
    const TAG='Login.js ';
    const [email,setEmail]=useState('');
    const [password,setPassword]=useState('');
    const [results,setResults]=useState([]);
    // const [valid,setValid]=useState(false);
    const history=useHistory();
    var count=0;


   
  
   
    
  /*  useEffect(()=>{
        db.collection('dummy_host').get().then((item)=>{
            const items=item.docs.map((doc)=>doc.data());
            // console.log('[Login.js] + items' + items);
            setResults(items);
        });
    },[])

<<<<<<< HEAD
   /* const submit=(e)=>{
        e.preventDefault();
        // console.log(userName,password);
        // ref.get().then((item)=>{
        //     const items=item.docs.map((doc)=>doc.data());
        //     setResults(items);
        // });
        // console.log(results);
        // console.log(results[0].username,results[0].password);
       results.map(result=>{
           if(result.username===userName && result.password===password){
                setValid(true);
                history.push('/events');
           }
       })
       
    }*/


   const checkhost=(uid)=>{
    db.collection('XOOG_host').doc(uid).get().then((doc)=>{
        if (doc.exists) {
            // console.log(TAG,"User is Host");
            dispatcher(setAuth());
            history.push('/Dashboard');
         
        } else {
            // doc.data() will be undefined in this case
            alert("You are not a teacher registered in XOOG. Contact +91 7358777690 to become a teacher");
            // console.log("No such document!");
        }
      });
   }
    
    

    const submit = (e)=>{
        e.preventDefault();
        // console.log(email,password);
        auth.signInWithEmailAndPassword(email, password).then((cred) => {
        //   console.log('[Login.js] '+'signed in'+ cred.user.uid);
          checkhost(cred.user.uid);
         
        })
        .catch((e) => {
            // console.log("error",e.message);
            alert( e.message);
        
        });

    }
    return (
        <div className={styles.login}>
            <div className={styles.loginLeft}>
                <img src={Logo} alt=''/>
                <div className={styles.loginLeftDetails}>
                    <h1>Competition Platform</h1>
                    <h3>Complete and Practice Puzzle</h3>
                </div>
            </div>
            <div className={styles.loginContainer}>
                <div className={styles.userImage}>
                {/* <img src={img2} alt=''/> */}
                <FiUser style={{width:"50px"}}/>
                </div>

                <form onSubmit={submit}>
                    
                    <h1>Log In</h1>
                    <div className={styles.inputDetails}>
                        <input id='Email' type='text' placeholder='Email' required onChange={(e)=>{
                            setEmail(e.target.value)
                        }} />
                    </div>
                    <div className={styles.inputDetails}>
                    
                    <input id='password'type='password' placeholder='Password' required onChange={(e)=>{
                        setPassword(e.target.value)
                    }}/>
                    </div>
                    
                    <button type='submit' className={styles.loginButton}>SIGN IN</button>
                </form>
                
            </div>
        </div>
    )
}



export default Login

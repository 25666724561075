import { act } from "react-dom/test-utils";

const TAG='CourseReducer ';

const CourseReducer = (state=null,action)=>{
    console.log('inside Course reducer');
     switch(action.type){
         case 'SET_COURSE_DETAILS':
            console.log(TAG,action.payload); 
            return{
                ...action.payload   
            };
        
      
           
          
         default:
             console.log('default switch '+state);
             return state;
     }

}

export default CourseReducer;
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router';
import {useSelector,useDispatch} from 'react-redux';
import { db } from '../../firebase';
import HostDetails from '../HostDetails/HostDetails';
import Table from '../Table/Table';
import './EventPage.css'
import Loader from '../Loader/Loader';
function EventPage({ title, id, category ,bnum }) {
	const TAG = 'EventPage.js ';
	const [start, setStart] = useState(false);
	const [batchArr, setBatchArr] = useState([]);
	const [noOfBatches, setNoOfBatches] = useState(0);
	const [batch, setBatch] = useState(0);
	const [batchNumber, setBatchNumber] = useState('');
	const createBatch = useRef();
	const totalBatch = useRef();
	const [eventName, setEventName] = useState('');
	// let { id, category } = useParams();
	const [loading, setLoading] = useState(false);
	// var  EventData =useSelector(state => {
	//     console.log(TAG,'inside useSelector');
	//     return state.EventReducer;
	//   });
	var EventData = null;

	console.log(TAG, category);
	const GetEventDetails = () => {
		console.log(TAG, id);
		console.log(TAG, EventData);
		if (EventData == null) {
			db.collection('XOOG_leaderboard')
				.doc(id)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setEventName(doc.data().EventName);
						getEventName(eventName);
						EventData = { ...doc.data(), id: doc.id };
						console.log(TAG, EventData);
						if (!EventData.batchCreated) {
							setStart(false);
						} else {
							setNoOfBatches(EventData.batchCount);
							setStart(true);
						}
					}
				})
				.catch((error) => {
					console.log('Error getting documents: ', error);
				});
		}

		console.log(EventData);
	};

	useEffect(() => {
		GetEventDetails();
	});

	useEffect(() => {
		const getOptions = () => {
			let content = [];
			for (let i = 1; i <= noOfBatches; i++) {
				content.push(`Batch ${i}`);
			}
			setBatchArr(content);
		};

		getOptions();
	}, [noOfBatches]);

	const hideBatchCreation = () => {
		createBatch.current.style.display = 'none';
	};

	const createBatchFunction = () => {
		const count_fieldName = category + '_Count';
		console.log(TAG, 'fieldname', count_fieldName);
		var count = EventData[count_fieldName];
		console.log(TAG, 'count', count);
		console.log(batch, 'batch count checking');
		if (batch === 0) {
			//show Error ***************************************
		} else {
			db.collection('XOOG_leaderboard')
				.doc(id)
				.collection(category + '_participants')
				.get()
				.then(function (querySnapshot) {
					setLoading(true);
					var ParticipantCount = 1;
					var BatchCount = 1;
					querySnapshot.forEach(function (doc) {
						// doc.data() is never undefined for query doc snapshots
						console.log(doc.id, 'YOOOOOOOO');
						var docId = doc.id;

						if (ParticipantCount <= batch) {
							db.collection('XOOG_leaderboard')
								.doc(id)
								.collection(category + '_participants')
								.doc(docId)
								.update({ Batch: `B${BatchCount}` });
							ParticipantCount++;
						} else {
							BatchCount++;
							ParticipantCount = 1;
							db.collection('XOOG_leaderboard')
								.doc(id)
								.collection(category + '_participants')
								.doc(docId)
								.update({ Batch: `B${BatchCount}` });
							ParticipantCount++;
						}

						console.log('participantNumber', ParticipantCount);
						console.log('BatchNumber', BatchCount);
						setNoOfBatches(BatchCount);
						console.log(doc.id, ' => ', doc.data());
					});
					db.collection('XOOG_leaderboard')
						.doc(id)
						.update({ batchCount: BatchCount, batchCreated: true });
					setLoading(false);
				});
		}
	};

	const getBatchNumber = (number) => {
		setBatchNumber(number);
	};

	const [name, setName] = useState('');
	const getEventName = (name) => {
		name = name.split(' ').join('');
		setName(name);
	};

	return (
		<div className='eventPage'>
			<div>
				{start && <h1>XOOG Hosting Platform</h1>}
				<h2>CATEGORY: {category}</h2>
			</div>

			{!start && (
				<div className='createBatch' ref={createBatch}>
					<h2>{eventName}</h2>

					<div className='batchCreation'>
						<div className='batchInput'>
							<label forhtml='batch'>
								Enter the number of students in a batch:{' '}
							</label>
							<input
								id='batch'
								type='number'
								min='1'
								value={batch}
								onChange={(e) => {
									setBatch(e.target.value);
								}}
							/>
						</div>
						<button
							className='createButton'
							onClick={() => {
								hideBatchCreation();
								createBatchFunction();
								setStart(false);
								setStart(true);
							}}>
							Create batch
						</button>
					</div>
				</div>
			)}

			<h3 className='tableName'>{title}</h3>

			{start &&
				(loading ? (
					<Loader />
				) : (
					<>
						<HostDetails
							batchArr={batchArr}
							batchNum={getBatchNumber}
							eventName={eventName}
							bnum={bnum}
						/>
						<Table number={bnum} name={name} allParticipants={true} />
					</>
				))}
		</div>
	);
}

export default EventPage;
var currentEvents=[];
var FutureEvents=[];
var pastEvents=[];
var currentTime=new Date();
const sortEvents=(events)=>{
    console.log('sortEvents Invoked',events);
    events.sort((a,b)=>{
        var time1=new Date(a.startTime);
        var time2=new Date(b.startTime);
        return time2-time1;
    })
    events.forEach((event)=>{
        if(events.startTime>=currentTime && events.endTime<=currentTime){
            currentEvents.push(event);
        }
    })
    events.forEach((event)=>{
        if(events.endTime>currentTime){
            pastEvents.push(event);
        }
    })
    events.forEach((event)=>{
        if(events.startTime>currentTime ){
            FutureEvents.push(event);
        }
    })
    console.log('Sorting finished',events)
    console.log(currentEvents,pastEvents,FutureEvents)
}
export {sortEvents,pastEvents,FutureEvents,currentEvents}
import React, { useEffect, useState } from "react";
import { auth, db } from "../../firebase";
import CardsDisplay from "../CardsDisplay/CardsDisplay";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import LoginForm from "../LoginForm/LoginForm";
import Loader from "../Loader/Loader";
import LearningModuleCard from "../LearningModuleCard/LearningModuleCard";
import styles from "./MyEventsPage.module.css";

const MyEventsPage = () => {
  const history = useHistory();
  const [tutorId, setTutorId] = useState("");
  const [authorised, setAuthorised] = useState(<Loader />);
  const [uncompletedEvents, setUncompletedEvents] = useState([]);
  const [unActive, setUnActive] = useState([]);
  const [active, setActive] = useState([]);

  const user = auth.currentUser;

  useEffect(() => {
    var uncompletedEvent = [];
    db.collection("XOOG_Learning")
      .where("tutor", "==", tutorId)
      .where("isCompletelyCreated", "==", "false")
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          uncompletedEvent.push({ id: doc.id, data: doc.data() });
        });
        setUncompletedEvents(uncompletedEvent);
      });
  }, [tutorId]);

  useEffect(() => {
    var unactive = [];
    db.collection("XOOG_Learning")
      .where("tutor", "==", tutorId)
      .where("isCompletelyCreated", "==", "true")
      .where("isXoogVerified", "==", "false")
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          unactive.push(
            <LearningModuleCard
              history={history}
              moduleObj={{ id: doc.id, data: doc.data() }}
              showTutor={false}
            />
          );
        });
        setUnActive([
          {
            cardsList: unactive,
            header: "Events Under Verification",
            coloredText: null,
            fallbackText: "No Events",
            xScrollable: true,
          },
        ]);
      });
  }, [tutorId]);

  useEffect(() => {
    var active = [];
    db.collection("XOOG_Learning")
      .where("tutor", "==", tutorId)
      .where("isXoogVerified", "==", "true")
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          active.push(
            <LearningModuleCard
              history={history}
              moduleObj={{ id: doc.id, data: doc.data() }}
              showTutor={false}
            />
          );
        });
        setActive([
          {
            cardsList: active,
            header: "Live Events",
            coloredText: null,
            fallbackText: "No Events",
            xScrollable: true,
          },
        ]);
      });
  }, [tutorId]);

  useEffect(() => {
    if (auth.currentUser) {
      setTutorId(user.uid);
      setAuthorised(null);
    } else setAuthorised(<LoginForm />);
  }, [auth.currentUser]);

  if (authorised) return authorised;

  return (
    <div className={styles.MyEventsPage}>
      <div className={styles.createEvent}>
        <div className={styles.createEventButton}>
          <Button onClick={() => history.push("/myevent/create")} variant="contained" size="large">
            Create Event
          </Button>
        </div>
        <div className={styles.uncompletedEvents}>
          {uncompletedEvents.map((val, index) => {
            return (
              <div
                className={styles.uncompletedEventsList}
                onClick={() => {
                  history.push(
                    `/Events/update/${val.id}/${val.data.completedTill}`
                  );
                }}
              >
                {val.data.ModuleName}
              </div>
            );
          })}
        </div>
      </div>

      {/* Unactive Events */}
      <div className={styles.unActiveEvents}>
        <CardsDisplay cardsContainerList={unActive} />
      </div>
      {/* active Events */}
      <div className={styles.activeEvents}>
        <CardsDisplay cardsContainerList={active} />
      </div>
    </div>
  );
};

export default MyEventsPage;

import React, { useEffect, useState } from "react";
import Styles from './Tab.module.css';


const Tab = (props)=>{

    console.log("tab js", props.courseId);
    
    const tabcontent = props.tabcontent;
    const [active,setactive]=useState(0);
    const [selectedComponent,setSelectedComponent] = useState(tabcontent[0].component);
  
    const onClickTab = (event) =>{
      
        if(event.target.id != active){
                  
            setSelectedComponent(tabcontent[event.target.id].component);
            setactive(event.target.id);
                    
        }
    }
   
    let index=0;
    const TabTitles = tabcontent.map((object) => {
        
        let cssclassname=Styles.activebutton;
      
     
        if(active == index){
           
            cssclassname=Styles.activebutton;
         
        }else{
            
        
           cssclassname=Styles.normalbutton;
           
        }
  
        index++;
        
        return (<button  id={index-1}onClick={onClickTab} className={cssclassname} >{object.title}</button>)
     
       
    });
    
     return(
         <div>
            <div className={Styles.tab}>
            
                 {TabTitles}

            </div>
            
            {selectedComponent}

        
        </div>
        );

}

export default Tab;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './OTPinput.module.css';

const OTPinput = (props) => {
  let [OTP, setOTP] = useState(Array.apply(null, Array(props.length)).map(function () { return ""; }));
  const boxesDisplay = [];
  for (let i = 1; i <= props.length; i++) {
    boxesDisplay.push(
      <input id={"OTPbox" + i}
      type="number"
        autoFocus={i == 1 ? props.autoFocus : false}
        className={styles.OTPbox}
        placeholder="#"
        onClick={(e) => { e.target.select(); }}
        onChange={(e) => { displayNumber(e.target); }}
        onKeyUp={(e) => { navigateBox(e.target, e.key); }}
        autoComplete={"off"}></input>
    );
  }
  const displayNumber = (inputTag) => {
    inputTag.value = inputTag.value[inputTag.value.length - 1];
    let newOTP = JSON.parse(JSON.stringify(OTP));
    newOTP[inputTag.id[inputTag.id.length - 1] - 1] = inputTag.value;
    setOTP(newOTP);
    navigateBox(inputTag, "ArrowRight");
  }

  useEffect(() => {
    // call the props.onChange
    props.onChange(OTP.join(""));
  }, [OTP]);

  const navigateBox = (currentInputTag, key) => {
    let currentBoxNo = currentInputTag.id[currentInputTag.id.length - 1];

    if (currentInputTag.value != "") {
      if (key == "ArrowRight") {
        if (currentBoxNo < props.length) {
          currentInputTag.parentNode.children[currentBoxNo].focus();
          currentInputTag.parentNode.children[currentBoxNo].select();
        }
        else {
          currentInputTag.parentNode.children[0].focus();
          currentInputTag.parentNode.children[0].select();
        }
      }
      else if (key == "ArrowLeft") {
        if (currentBoxNo > 1) {
          currentInputTag.parentNode.children[currentBoxNo - 2].focus();
          currentInputTag.parentNode.children[currentBoxNo - 2].select();
        }
        else {
          currentInputTag.parentNode.children[props.length - 1].focus();
          currentInputTag.parentNode.children[props.length - 1].select();
        }
      }
    }
  }

  return (
    <div className={styles.OTPboxesContainer}>
      {boxesDisplay}
    </div>
  );
};

// OTPinput.propTypes = {};

// OTPinput.defaultProps = {};

export default OTPinput;

import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { db } from '../../firebase';
import HostDetails from '../HostDetails/HostDetails';
import { useHistory } from 'react-router';
import ParticipantTable from '../ParticipantTable/ParticipantTable';
import EventPage from '../EventPage/EventPage';
import './EventPage.css';
import Loader from '../Loader/Loader';
function EventPage1({ title }) {
	const a = useSelector((state) => state);
	console.log('this is staoe 111111111 ;', a);
	const history = useHistory();
	const TAG = 'EventPage.js ';
	const [start, setStart] = useState(false);
	const [batchArr, setBatchArr] = useState([]);
	const [whichtab, setwhichtab] = useState('participate');
	const [noOfBatches, setNoOfBatches] = useState(0);
	const [clickedbatchnuber, setclickedbatchnuber] = useState(null)
    const [SHOW, setSHOW] = useState(false)
	const [batch, setBatch] = useState(0);
	const [batchNumber, setBatchNumber] = useState('');
	const createBatch = useRef();
	const totalBatch = useRef();
	const [eventName, setEventName] = useState('');
	let { id, category } = useParams();
	const [loading, setLoading] = useState(false);
	// var  EventData =useSelector(state => {
	//     console.log(TAG,'inside useSelector');
	//     return state.EventReducer;
	//   });
	var EventData = null;

	console.log(TAG, category);
	const GetEventDetails = () => {
        console.log("this is number of batcjhes : ///////////////////////////////////////////////",noOfBatches)
		console.log(TAG, id);
		console.log(TAG, EventData);
		if (EventData == null) {
			db.collection('XOOG_leaderboard')
				.doc(id)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setEventName(doc.data().EventName);
						getEventName(eventName);
						EventData = { ...doc.data(), id: doc.id };
						console.log(TAG, EventData);
						if (!EventData.batchCreated) {
							setStart(false);
						} else {
							const a =[]
							for(let i =0;i<EventData.batchCount;i++){
								a.push(i+1)
							}
							setNoOfBatches(EventData.batchCount);
							setStart(true);
						}
					}
				})
				.catch((error) => {
					console.log('Error getting documents: ', error);
				});
		}

		console.log(EventData);
	};

	useEffect(() => {
		GetEventDetails();
	});

	useEffect(() => {
		const getOptions = () => {
			let content = [];
			for (let i = 1; i <= noOfBatches; i++) {
				content.push(`Batch ${i}`);
			}
			setBatchArr(content);
		};

		getOptions();
	}, [noOfBatches]);

	const hideBatchCreation = () => {
		createBatch.current.style.display = 'none';
	};

	const createBatchFunction = () => {

							const a = [1, 2, 3, 34, 4, 4, 5, 5, 6, 6, 7, 7, 78, 8];
							const size1 = a.length;
							const howmanybatchs =4
							let batchnumber1=1
							const eachmem = (size1 / howmanybatchs).toFixed(0);
							let inc =0;
							let empetyarray = [];
							let rem = [];

							for(let i =0 ;i<a.length;i++){
								if (inc <eachmem) {
									rem.push(a[i]);
									inc++;			
									if (inc == eachmem || (batchnumber1 == howmanybatchs && i == (a.length)-1) ) {
										batchnumber1++
										empetyarray.push(rem);
										rem = [];
										inc = 0;
									}
						
								}
							};

							console.log('this is empty array', empetyarray);

// 		const count_fieldName = category + '_Count';
// 		console.log(TAG, 'fieldname', count_fieldName,"LLLLLLLLLLLLLLL",id);
// 		if (batch === 0) {
// 			//show Error ***************************************8
// 			console.log("this is an error")
// 		} else {
// 			console.log('this is an how amny batches  dwdwqdwqwedqweqwewqewqeqweqwe:',batch);

// 			db.collection('XOOG_leaderboard')
// 				.doc(id)
// 				.collection(category + '_participants')
// 				.get()
// 				.then(function (querySnapshot) {
// 					setLoading(true);
// 					var ParticipantCount = 1;
// 					var BatchCount = 1;
// 					console.log("thisisiqueary sanp0 shot",querySnapshot.size)
// 					const size =querySnapshot.size
// 					const eachbatchhas= (size/batch).toFixed(0)
// 					let decrememnt =eachbatchhas //5
// 					console.log("each batch has  pppppppppppppppppppppppppp:",eachbatchhas)
// 					querySnapshot.forEach(function (doc) {
// 						// doc.data() is never undefined for query doc snapshots
// 						console.log(doc.id, 'YOOOOOOOOoooooooooooooooooooooooooooooooooooooooooooooooooooooooo');
// 						var docId = doc.id;
// 						if(decrememnt>1){
// 							db.collection('XOOG_leaderboard')
// 								.doc(id)
// 								.collection(category + '_participants')
// 								.doc(docId)
// 								.update({ Batch: `B${BatchCount}` });
// 								decrememnt--
// 								if(decrememnt ==0){
// 									decrememnt = eachbatchhas;
// 								    BatchCount++;
// 								}
// 						}
				
						
// // batch 

// 						if (ParticipantCount <= batch) { 
// 							db.collection('XOOG_leaderboard')
// 								.doc(id)
// 								.collection(category + '_participants')
// 								.doc(docId)
// 								.update({ Batch: `B${BatchCount}` });
// 							ParticipantCount++;
// 						} else {
// 							BatchCount++;
// 							ParticipantCount = 1;
// 							db.collection('XOOG_leaderboard')
// 								.doc(id)
// 								.collection(category + '_participants')
// 								.doc(docId)
// 								.update({ Batch: `B${BatchCount}` });
// 							ParticipantCount++;
// 						}

// 						console.log('participantNumber', ParticipantCount);
// 						console.log('BatchNumber', BatchCount);
// 						setNoOfBatches(BatchCount);
// 						console.log(doc.id, ' => ', doc.data());
// 					});
// 					db.collection('XOOG_leaderboard')
// 						.doc(id)
// 						.update({ batchCount: BatchCount, batchCreated: true });
// 					setLoading(false);
// 				});
// 		}
	};

		const updateShow = async (id,temp) => {
		console.log("thismi si clicked",id,temp)
		var allParticipantData = [];
			await db
				.collection('XOOG_leaderboard')
				.doc(id)
				.collection(`${temp}_participants`)
				.get()
				.then((response) => {
					response.forEach((participantDetails) => {
						const participant = participantDetails.data();
						//  console.log(participant.A05)
						if (participant.A05.length === 0) {
							console.log('Working');
							participant.A05 = 99999;
						}
						if (
							participant.A05 === 'Not completed' ||
							participant.A05 === 'No results' ||
							participant.A05 === ''
						) {
							participant.A05 = 999999;
						}
						console.log(participant);
						allParticipantData.push(participant);
					});
					console.log('BeforeSorting', id, allParticipantData);

					allParticipantData.sort((a, b) => {
						return a.A05 - b.A05;
					});
					
					console.log("this is al particip[arte data",allParticipantData);
					var rank = 0;
					for (var i = 0; i < allParticipantData.length; i++) {
						console.log('Inside Loop', allParticipantData[i]);
						console.log('all id', allParticipantData[i]._Id);
						db.collection('XOOG_leaderboard')
							.doc(id)
							.collection(`${temp}_participants`)
							.doc(allParticipantData[i]._Id)
							.update({ Rank: rank + 1 });
						db.collection('XOOG_users')
							.doc(allParticipantData[i]._Id)
							.collection('participate')
							.doc(`${id}_${temp}`)
							.update({ Rank: rank + 1 });
						rank++;
					}
					allParticipantData = [];
				});

			db.collection('XOOG_leaderboard')
				.doc('Overall_Leaderboard')
				.collection(`${temp}_participants`)
				.get()
				.then((response) => {
					response.forEach((participantDetails) => {
						const participant = participantDetails.data();
						participant._Id = participantDetails.id;
						if (
							participant.A05 === 'Not completed' ||
							participant.A05 === 'No results' ||
							participant.A05 === ''
						) {
							participant.A05 = 999999;
						}
						console.log(participant);
						allParticipantData.push(participant);
					});
					console.log('BeforeSorting', id, allParticipantData);
					allParticipantData.sort((a, b) => {
						return a.A05 - b.A05;
					});
					console.log('updating overall leaderboard');
					console.log(allParticipantData);
					var rank = 0;
					for (var i = 0; i < allParticipantData.length; i++) {
						console.log(
							'Inside Loop overall leaderboard',
							allParticipantData[i]
						);
						console.log('id', allParticipantData[i]._Id);
						db.collection('XOOG_leaderboard')
							.doc('Overall_Leaderboard')
							.collection(`${temp}_participants`)
							.doc(allParticipantData[i]._Id)
							.update({ Rank: rank + 1, Name: allParticipantData[i].Name });
						rank++;
					}
				});
		
		
		db.collection('XOOG_leaderboard').doc(id).update({ show: true });
	};

	const getBatchNumber = (number) => {
		setBatchNumber(number);
	};

	const [name, setName] = useState('');
	const getEventName = (name) => {
		name = name.split(' ').join('');
		setName(name);
	};

	const goToCreateBatch = (num) => {
		// history.push(`/compete/${id}/${category}`);
		setclickedbatchnuber(num)
        setSHOW(true)
	};
	const changetab = (tab)=>{
		setwhichtab(tab)
		setSHOW(false)
	}

	return (
		<div className='eventPage'>
			<div className={start ? 'eventBackGround' : 'categoryName'}>
				{start && <h1>XOOG Hosting Platform</h1>}
				<h2>CATEGORY: {category}</h2>
			</div>
			<div className='tabsection'>
				<div className='tabs'>
					<span onClick={() => changetab('participate')}>
						Participants
						<div className={whichtab == 'participate' && 'rec'}></div>
					</span>
					<span onClick={() => changetab('Batches')}>
						Batches
						<div className={whichtab == 'Batches' && 'rec'}></div>
					</span>
					<span onClick={() => changetab('Results')}>
						Results
						<div className={whichtab == 'Results' && 'rec'}></div>
					</span>
				</div>
				<div className='tabarea'>
					{whichtab == 'participate' &&
						start &&
						(loading ? (
							<Loader />
						) : (
							<ParticipantTable
								number={batchNumber}
								name={name}
								allParticipants={true}
							/>
						))}
					{whichtab == 'Batches' ? (
						!SHOW ? (
							noOfBatches == 0 ? (
								<div className='createBatch' ref={createBatch}>
									<h2>{eventName}</h2>

									<div className='batchCreation'>
										<div className='batchInput'>
											<label forhtml='batch'>
												Enter the number of students in a batch:{' '}
											</label>
											<input
												id='batch'
												type='number'
												min='1'
												value={batch}
												onChange={(e) => {
													setBatch(e.target.value);
												}}
											/>
										</div>
										<button
											className='createButton'
											onClick={() => {
												// hideBatchCreation();
												createBatchFunction();
											}}>
											Create batch
										</button>
									</div>
								</div>
							) : (
								[...Array(noOfBatches).keys()].map((ele)=>
								 <button className="batchbutton" onClick={()=>goToCreateBatch(ele+1)}>Batch {ele+1}</button>
								)
								
							)
						) : (
							<EventPage id={id} category={category} bnum={clickedbatchnuber} />
						)
					) : null}

					{whichtab == 'Results' && (
						 <button className="batchbutton" onClick={() => updateShow(id, category)}>Result</button>
					
					)}
				</div>
			</div>

			<h3 className='tableName'>{title}</h3>
		</div>
	);
}

export default EventPage1;

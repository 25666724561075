import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import styles from "./Nav.module.css";
import xooglogo from '../../images/xoog.svg';
import firebase from "firebase";
import { auth, db } from "../../firebase";
import './nav.css';
import { useSelector } from "react-redux";

function Nav() {
  const history = useHistory();
  const [authorised, setAuthorised] = useState(false);
  const [showrectangle, setshowrectangle] = useState(false);

  const isLogin = useSelector(state => {

    return state.loginReducer;
  });




  const subitem = (e) => {
    console.log(typeof toString(e.target.clientX));
    console.log('widht', e.offsetWidth);
    const rec = document.getElementsByClassName('rectangle')[0].style.marginLeft = `${e.clientX}px`;
    document.getElementsByClassName('rectangle')[0].style.width = `${e.target.offsetWidth}px`;
    console.log(typeof e.clientX)
    console.log(e);
  }

  const logout = () => {
    setshowrectangle(false);
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log("logged out");
        history.push("/")
        window.location.reload();
        // Sign-out successful.
      })
      .catch((error) => {
        console.log(error);
        // An error happened.
      });
  };

  auth.onAuthStateChanged((host) => {
    if (host) setAuthorised(true);
    else setAuthorised(false);
  });

  const goToHomePage = () => {
    console.log("clicked");
    history.push('/');
  }
  return (
    <div className={styles.navWrapper}>

      <div className={styles.logo} >
        <a onClick={goToHomePage}>
          <img onclick={goToHomePage} src={xooglogo} alt="logo" />
        </a>
      </div>

      {(isLogin.valid) ? null : (
        <div className={styles.linkWrapper}>

          {/*}   <NavLink
          activeClassName={styles.selected}
          className={styles.linkItem}
          to="/Dashboard"
        >
          {/* <img src={listIcon} alt="leaderboard" /> */}
          {/*
          <span>Dashboard</span>
        </NavLink>
        */}




          {/*  <NavLink
          activeClassName={styles.selected}
          className={styles.linkItem}
          to="/events"
        >
          {/* <img src={listIcon} alt="Events" />*/}
          {/*}
          <span>Events</span>
        </NavLink>
        */}

          <NavLink activeClassName={styles.selected} className={styles.linkItem} to="/courses">
            {/* <img src={timerIcon} alt="timer" /> */}
            <span>Courses</span>
          </NavLink>
         {/* <NavLink activeClassName={styles.selected} className={styles.linkItem} to="/myevents">
            {/* <img src={timerIcon} alt="timer" /> */}
           {/*} <span>Events</span>
          </NavLink>
      */}
          
          <NavLink
            activeClassName={styles.selected}
            className={styles.linkItem}
            to="/Students"
          >
            {/* <img src={listIcon} alt="Events" />*/}
            <span>Students</span>
          </NavLink>

        </div>
      )}

      <div className={styles.profileWrapper}>
        {
          authorised ?
            <div className={styles.profileCircle} onClick={() => setshowrectangle(!showrectangle)}>P</div>
            :
            <div className={styles.loginOrSignup} onClick={() => history.push("/login")}>
              Signup
            </div>
        }
      </div>
      {showrectangle && (
        <div className={styles.dropdown}>
          <span
            activeClassName={styles.active}
            className={styles.dropdownItem}
            onClick={logout}
          >
            logout
          </span>
        </div>
      )}



    </div>

    // )
  );
}

export default Nav

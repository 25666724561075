import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { db, auth } from '../../firebase';
import './Events.css';
import { setEventDetails } from '../../react-redux/action/action';
import Button from '../Button/Button';
import EventComponent from '../EventComponent/EventComponent';
import Loader from '../Loader/Loader';
import { sortEvents } from '../../utils/sortEvents';
import FutureEvents from '../EventComponent/FutureEvents';
import EventsOver from '../EventComponent/EventsOver';

function Events() {
	const history = useHistory();
	const dispatcher = useDispatch();
	var hostId = null;
	// const [hostId,setHostId]=useState('');
	let EventList = null;
	const [eventId, setEventId] = useState('');
	const [EventState, setEventState] = useState(null);
	var EventData = [];
	var cubeType = '3x3';
	var event = 'E01';
	const TAG = '[Events.js] ';
	const [click, setClick] = useState('');
	const [createarray, setcreatearray] = useState([])
	const selection = useRef();
	const [eventName, setEventName] = useState('');
	const [loading, setLoading] = useState('loading');

	// const visible=()=>{
	//     selection.current.style.visibility='visible';
	// }

	const state_valid = useSelector((state) => {
		return state.authValid.valid;
	});

	const checkhost = (uid) => {
		if (!state_valid) {
			console.log('Yes2');
			db.collection('XOOG_host')
				.doc(uid)
				.get()
				.then((doc) => {
					if (doc.exists) {
						console.log(TAG, 'User is Host');

						getEventData();
					} else {
						// doc.data() will be undefined in this case
						history.push('/');
						console.log('No such document!');
					}
				});
		} else {
			getEventData();
		}
	};

	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			if (user) {
				hostId = user.uid;
				// setHostId(user.uid)
				console.log(TAG, hostId);
				console.log('Yes1');
				checkhost(hostId);
				console.log('Yes3');
				// ...
			} else {
				// User is signed out
				// ...
				if (!state_valid) {
					history.push('/');
				}
			}
		});
	}, []);

	const handleChange = (newValue, event_Name, event_id) => {
		setClick(newValue);
		setEventName(event_Name);
		setEventId(event_id);
		// visible();
	};

	var number = 0;

	const changePage = () => {
		history.push(`/events/${eventId}/${click}`);
	};

	var EventShow;
	var FutureShow;
	var EventsCompleted;
	const numberInc = () => {
		number++;
	};

	if (EventState != null) {
		  EventShow= EventState[0].puzzle.map(type=>(
		   <Button onClick={handleChange} type={type}/>
		 ))
		if (loading) {
			EventShow = <Loader />;
			
		} else {
			sortEvents(EventState);
			
			EventShow = EventState.map((events,index) => {
				number++;
				return (
					<EventComponent
						number={number}
						numberInc={numberInc}
						EventState={events}
						onClick={handleChange}
						changePage={changePage}
					/>
				);
			});
			EventShow =<div>No Events</div>
			
			console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk",EventState)
			EventsCompleted = EventState.map((events) => {
				number++;
				return (
					<EventsOver
						number={number}
						numberInc={numberInc}
						EventState={events}
						onClick={handleChange}
						changePage={changePage}
					/>
				);
			});

		}
	} else {
		EventShow = <Loader />;
	}

	const getEventData = async () => {
		console.log(TAG, 'checkhost');
		setLoading(true);
		await db
			.collection('XOOG_leaderboard')
			.where('hostedBy', '==', hostId)
			.get()
			.then((querySnapshot) => {
				const a =[]
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						a.push({ ...doc.data(), id: doc.id });
						var event = { ...doc.data(), id: doc.id };
						EventData.push(event);
					}
		
				
					setEventState([...EventData]);
					console.log(EventState);
					console.log(TAG, EventData);
					console.log(TAG, 'Eventstate', EventState);
				});
				    console.log('this  is  a >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', a);
					setcreatearray(a);

				EventList = Object.keys(EventData).map((igkey) => {
					return <li>{EventData[igkey].EventName}</li>;
				});
				//Have to change
				dispatcher(setEventDetails(EventData[0]));
				//  EventId=EventData[0].id;
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
		setLoading(false);
	};

	return (
		<div className='events'>
			<h1 className='eventTitle'>Events Page</h1>
			<div>
				<h1 className='eventstype'>Live Competetions</h1>
				{EventShow ? EventShow : <div>No Live Competetions</div>}
			</div>
			{FutureShow !== null && <h1 className='eventstype'>Upcoming Events</h1>}
			<div className='futureEventsComingSoon'>
				{FutureShow ? FutureShow : <div>No Upcoming Events</div>}
			</div>
			<h1 className='eventstype'>Events Completed</h1>
			{/* <div className='eventscompleted'>{EventsCompleted}</div> */}
			{createarray.length > 0 ? (
				<div className='eventscompleted'>
					{createarray.map((array, index) => (
						<EventsOver
							number={index}
							numberInc={numberInc}
							EventState={array}
							onClick={handleChange}
							changePage={changePage}
						/>
					))}
				</div>
			) : (
				<div>No Events</div>
			)}

			{/* <div className='selection' ref={selection}>
                <p>You have selected {click} and event {eventName}</p>
                <button onClick={()=>{
                  history.push(`/events/${eventId}/${click}`)
                }}>Continue</button>
              </div> */}
		</div>
	);
}

export default Events;

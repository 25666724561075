import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./LoginForm.module.css";
import { auth, db } from "../../firebase";
import firebase from "firebase";
import { firebaseApp } from "../../firebase";

import img_1 from "../../assets/Login_form_img_1.svg";
import img_2 from "../../assets/Login_form_img_2.svg";

import _XOOG_BUILD_VARS from "../../DataStructures/Build_Vars";
import OTPinput from "../OTPinput/OTPinput";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useHistory } from "react-router";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import SaveIcon from "@mui/icons-material/Save";

import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { useDispatch } from "react-redux";
import { setIsLoginPage } from "../../react-redux/action/action";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

var isLoginDone =true;
let recaptcha = null;
const LoginForm = () => {

  const dispatcher = useDispatch();
  dispatcher(setIsLoginPage(true));

  const RESEND_OTP_INTERVAL = 30;
  const timerRef = useRef(null);
  const [resendOTPtime, setResendOTPtime] = useState(RESEND_OTP_INTERVAL);
  const [enableResendOTP, setEnableResendOTP] = useState(false);
  const [nomoreOTPresends, setNomoreOTPresends] = useState(false);




  const history = useHistory();

  const MODAL_MODES = {
    WRITE: 0,
    LOADING: 1
  };
  const FORM_PAGE = {
    GET_OTP: 0,
    VERIFY_OTP: 1,
    CREATE_ACCOUNT: 2,
  };
  const OTP_LENGTH = 6;
  Object.freeze(MODAL_MODES);
  Object.freeze(FORM_PAGE);


  const [formPage, setFormPage] = useState(FORM_PAGE.GET_OTP);
  const [modalMode, setModalMode] = useState(MODAL_MODES.WRITE);

 
  
  const [currentInputObj, setCurrentInputObj] = useState({
    phoneNumber: ""
  });
  const [formData, setFormData] = useState({});
  const [formDataValidity, setFormDataValidity] = useState({});
  const [OTPsent, setOTPsent] = useState(null);

  const [canShowInputError, setCanShowInputError] = useState(false);

  const [vwGreaterThan820px, setVwGreaterThan820px] = useState(window.innerWidth > 820);

  const checkIfViewWidthGreaterThan820px = () => {
    setVwGreaterThan820px(window.innerWidth > 820);
  }

  useEffect(() => {
    window.addEventListener("resize", checkIfViewWidthGreaterThan820px);
    return () => {
      window.removeEventListener("resize", checkIfViewWidthGreaterThan820px);
      dispatcher(setIsLoginPage(false));
     
      if(auth.currentUser&&(!isLoginDone)){
      
        auth.signOut().then(()=>{
        
        })
        
      }
    };
  }, [])



  useEffect(() => {
    updateFormData();
  }, [currentInputObj]);

  useEffect(() => {
    updateFormDataValidity();
  }, [formData]);

  const updateFormData = () => {
    setFormData(prevState => ({
      ...Object.assign(prevState, currentInputObj)
    }));
  };

  const updateFormDataValidity = () => {
    let regEx;
    if (Object.keys(currentInputObj)[0] == "phoneNumber") {
      regEx = /^(?!0)[0-9]{7,}$/;
    } else if (Object.keys(currentInputObj)[0] == "OTP") {
      // RegExp is hardcoded to be 4, should change it to variable length using OTP_LENGTH
      regEx = /^[0-9]{6}$/;
    } else if (Object.keys(currentInputObj)[0] == "email") {
      regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    } else if (Object.keys(currentInputObj)[0] == "name") {
      regEx = /^[a-zA-Z ,."]{5,}$/;
    }
    setFormDataValidity(prevState => (
      {
        ...Object.assign(prevState, { [Object.keys(currentInputObj)[0]]: regEx.test(String(Object.values(currentInputObj)[0]).toLowerCase()) })
      }
    ));
  };
  /*

  const getOTP = () => {
    console.log("get OTP");
    setCurrentInputObj({ phoneNumber: "+" + formData.phoneNumber });
    // recaptcha can be rendered only after its container is added to DOM
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
      "size": "invisbile",
      "callback": (response) => {
        console.log("reCAPTCHA verified");
        setModalMode(MODAL_MODES.LOADING);
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        const phoneNumber = formData.phoneNumber;
        const appVerifier = window.recaptchaVerifier;
        console.log("phone ", phoneNumber);
        auth.signInWithPhoneNumber(phoneNumber, appVerifier)
          .then((confirmationResult) => {
            console.log("confirmation result");
            setOTPsent(confirmationResult.verificationId);
            proceed();
          }).catch(err => {
            console.error("error",err);
            // reset reCAPTCHA
            window.recaptchaVerifier.render()
              .then((widgetId) => {
                firebase.auth.grecaptcha.reset(widgetId);
                window.recaptchaWidgetId = widgetId;
              });
          });
      },
      // "expired-callback": function () {
      //   console.log("Verification failed");
      // }
    });
    window.recaptchaVerifier.render().then(widgetId => {
      window.recaptchaWidgetId = widgetId;
    });
  };
*/

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    return {
      total, seconds
    };
  }

  const calculateResendOTPtime = (e) => {
    let { total, seconds }
      = getTimeRemaining(e);
    if (total > 0) {
      setEnableResendOTP(false);
      setResendOTPtime(seconds);
    } else {
      setEnableResendOTP(true);
    }
  }

  const resetResendOTPtimer = (e) => {
    setResendOTPtime(RESEND_OTP_INTERVAL);
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setEnableResendOTP(false);
    timerRef.current = setInterval(() => {
      calculateResendOTPtime(e);
    }, 1000)
  }
  const getResendOtpTimerDeadline = () => {
    let deadline = new Date();

    // This is where you need to adjust if 
    deadline.setSeconds(deadline.getSeconds() + RESEND_OTP_INTERVAL);
    return deadline;
  }

  const getOTP = (resend) => {
    setNomoreOTPresends(true);
    let phoneNumber = formData.phoneNumber;
    if (!resend) {
      setNomoreOTPresends(false);
      setCurrentInputObj({ phoneNumber: "+" + formData.phoneNumber });
      phoneNumber = "+" + formData.phoneNumber;
      setModalMode(MODAL_MODES.LOADING);
    }

    if (recaptcha == null) {

      recaptcha = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
        "size": "invisible"
      });
    }

    auth.signInWithPhoneNumber(phoneNumber, recaptcha).then(function (confirmationResult) {
      if (!resend) {
        setOTPsent(confirmationResult.verificationId);
        setModalMode(MODAL_MODES.WRITE);
        resetResendOTPtimer(getResendOtpTimerDeadline());
        proceed();
      }
    }).catch(function (error) {
      setModalMode(MODAL_MODES.WRITE);
      console.log("error", error);
      setCurrentInputObj({ phoneNumber: formData.phoneNumber });
      alert("Unable to send OTP Due To",error.message);
    });
  }

  useEffect(() => {
    if (typeof window.recaptchaVerifier != "undefined") {
      // delete the existing recaptcha if the phone number entered has changed
      delete window.recaptchaVerifier;
      delete window.recaptchaWidgetId;
    }
  }, [formData.phoneNumber]);

  const verifyOTP = () => {
    setModalMode(MODAL_MODES.LOADING);
    const credential = firebase.auth.PhoneAuthProvider.credential(OTPsent, formData.OTP);
    auth.signInWithCredential(credential).then(() => {
      console.log("OTP verified");
      clearInterval(timerRef.current);
      checkIfHostExist();
    })
      .catch((error) => {
      
      })
  };

  const checkIfHostExist = () => {
    const uid = auth.currentUser.uid;
    db.collection("XOOG_host").doc(uid).get().then((doc) => {
    
      if (doc.exists) {
     
        isLoginDone=true;
        history.push('/courses');

      } else {
      
        isLoginDone=false;
        // doc.data() will be undefined in this case
        proceed();
      }
    });
  }

  const goBack = () => {
    setCurrentInputObj({ phoneNumber: "" });
    clearInterval(timerRef.current);
    setFormPage(FORM_PAGE.GET_OTP);
  };

  const createNewHost = () => {
    db.collection(_XOOG_BUILD_VARS.COLLECTION.HOSTS).doc(auth.currentUser.uid).set({
      HostName: formData.name,
      email: formData.email,
      phoneNumber: formData.phoneNumber
    }).then((doc) => {
      alert("Sign Up Successful : " + formData.name);
      isLoginDone=true;
      history.push('/courses/create');
    })

  }

  const proceed = () => {
    setModalMode(MODAL_MODES.WRITE);
    // console.log(formData);
    if (formPage == FORM_PAGE.GET_OTP)
      setFormPage(FORM_PAGE.VERIFY_OTP);
    else if (formPage == FORM_PAGE.VERIFY_OTP) {
      setFormPage(FORM_PAGE.CREATE_ACCOUNT);
    } else if (formPage == FORM_PAGE.CREATE_ACCOUNT) {
      if (formDataValidity.name && formDataValidity.email) createNewHost();
      else setCanShowInputError(true);
      //  setModalMode(MODAL_MODES.LOADING);
    }
  };

  // const CustomFormInput = (props) => {
  //   return (
  //     <div className={styles.customInput}>
  //       <span className={styles.inputIcon}>
  //         {props.icon}
  //       </span>
  //       <input className={props.className} type={props.type} placeholder={props.placeholder} onChange={props.onChange}></input>
  //     </div>
  //   );
  // }


  return <div className={styles.modalBackdrop}>
    <div className={styles.modal}>
      <>
        <div className={formPage == FORM_PAGE.GET_OTP ? styles.modalLeftSideWhite : styles.modalLeftSideBlue}>
          <div className={styles.leftSideContentContainer}>
            {
              vwGreaterThan820px ? (
                <p className={styles.leftSideTextContainer}>
                  <b>
                    A FEW CLICKS AWAY FROM CREATING YOUR FIRST COURSE
                  </b>
                </p>
              ) : null
            }
            <div className={styles.leftSideImgContainer}>
              <img src={formPage == FORM_PAGE.GET_OTP ? img_1 : img_2} />
            </div>
            {
              !vwGreaterThan820px ? (
                <p className={styles.leftSideTextContainer}>
                  <b>
                    A FEW CLICKS AWAY FROM CREATING YOUR FIRST COURSE
                  </b>
                </p>
              ) : null
            }
          </div>
        </div>
        <div className={formPage == FORM_PAGE.GET_OTP ? styles.modalRightSideBlue : styles.modalRightSideWhite}>
          <div className={styles.modalRightSideContentContainer}>
            {
              formPage != FORM_PAGE.GET_OTP ? (
                <div className={styles.backBtnContainer}>
                  <ArrowBackIcon className={styles.backBtn} onClick={() => { goBack(); }} title="Back"/>
                </div>
              ) : null
            }
            <div className={styles.modalFormContainer}>
              <div className={styles.modalForm}>
                {
                  formPage == FORM_PAGE.GET_OTP ? (
                    <>
                      <Typography style={{marginTop:'5vh'}}>
                        <b>Enter Phone Number to Verify</b>
                      </Typography>
                      <div className={styles.phoneNumberContainer}>
                        <PhoneInput
                          style={{ color: "black" }}
                          country={"in"}
                          value={formData.phoneNumber}
                          autoFocus={true}
                          onChange={(phoneNumber) => { setCurrentInputObj({ phoneNumber: phoneNumber }) }}
                        />
                      </div>
                      <Box sx={{ marginY: "30px" }}>
                        {
                          modalMode == MODAL_MODES.WRITE ? (
                            formDataValidity.phoneNumber ? (
                              <Button variant="contained" onClick={() => { getOTP(false); }} >GET OTP</Button>
                            ) : null
                          ) : (
                            <>
                              <LoadingButton
                                loading
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="outlined"
                              >
                                Sending OTP
                              </LoadingButton>
                            </>
                          )
                        }
                      </Box>
                    </>
                  ) : (
                    formPage == FORM_PAGE.VERIFY_OTP ? (
                      <>
                        <Typography className={styles.warnText} style={{fontSize:'small'}}>
                          Do NOT CLOSE or REFRESH the page
                        </Typography>
                        <Typography className={styles.mildText}style={{fontSize:'small'}}>
                          OTP has been sent to {formData.phoneNumber}
                        </Typography>
                        <Typography>
                          <b>Enter OTP</b>
                        </Typography>
                        <OTPinput length={OTP_LENGTH} autoFocus={true} onChange={(OTP) => { setCurrentInputObj({ OTP: OTP }); }} />

                        {nomoreOTPresends ?
                          (
                            <>OTP resent. Refresh and try again if you haven't received</>
                          ) : (
                            enableResendOTP ? (
                              <span>Havent received OTP yet? <span className={styles.linkBtn} onClick={() => { getOTP(true); }}>Resend OTP</span></span>
                            ) : (
                              <Typography className={styles.mildText}>
                                Resend OTP in {resendOTPtime} seconds
                              </Typography>
                            )
                          )
                        }

                        <Box sx={{ marginY: "30px" }}>
                          {
                            modalMode == MODAL_MODES.WRITE ? (
                              formDataValidity.OTP ? (
                                <Button variant="contained" onClick={() => { verifyOTP() }}>Verify OTP</Button>
                              ) : null
                            ) : (
                              <LoadingButton
                                loading
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="outlined"
                              >
                                Verifying OTP
                              </LoadingButton>
                            )
                          }
                        </Box>
                      </>
                    ) : (
                      formPage == FORM_PAGE.CREATE_ACCOUNT ? (
                        <>
                          <Typography>
                            <b>Create Your Account</b>
                          </Typography>
                          <TextField id="email-input" label="Email" variant="outlined" type="email"
                            error={canShowInputError && !formDataValidity.email}
                            style={{marginTop:'3px'}}
                            helperText={canShowInputError && !formDataValidity.email ? "enter a valid email" : null}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailIcon />
                                </InputAdornment>
                              )
                            }}
                            autoFocus={true}
                            placeholder="eg: uniquename@gmail.com"
                            onChange={(e) => { setCurrentInputObj({ email: e.target.value }) }}
                          />
                          <TextField id="name-input" label="Name" variant="outlined" type="text" placeholder="Name"
                            error={canShowInputError && !formDataValidity.name}
                            style={{marginTop:'7px'}}
                            helperText={canShowInputError && !formDataValidity.name ? "name should have minimum 5 characters and can only contain alphabets, space, comma, dot, apostrophe" : null}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon />
                                </InputAdornment>
                              )
                            }}
                            placeholder="Name"
                            onChange={(e) => { setCurrentInputObj({ name: e.target.value }) }}
                          />
                          <Box sx={{ marginY: "30px" }}>
                            {
                              modalMode == MODAL_MODES.WRITE ? (
                                <Button variant="contained" onClick={() => { proceed(); }}>Signup</Button>
                              ) : (
                                <LoadingButton
                                  loading
                                  loadingPosition="start"
                                  startIcon={<SaveIcon />}
                                  variant="outlined"
                                >
                                  Signing up
                                </LoadingButton>
                              )
                            }
                          </Box>
                        </>
                      ) : null
                    )
                  )
                }
                <span id="recaptcha-container"></span>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  </div>;
};

// LoginForm.propTypes = { };

// LoginForm.defaultProps = { };

export default LoginForm;

import React from "react";
import Styles from "./Card.module.css";

const Card = (props) =>{

    console.log('color',props.backgroundcolor);

    return(
        <div className={Styles.cardview} style={{backgroundColor:props.backgroundColor}}>
             <h1 className={Styles.title} style={{color:props.textColor}}>{props.title}</h1>
             <h2 className={Styles.number} style={{color:props.numberColor}}>{props.number}</h2>
        </div>
    );
}

export default Card;
import React, { useEffect, useState } from "react";

import RequestCard from "./RequestCard/RequestCard";


import Styles from './StudentRequests.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { auth, db } from "../../../firebase";
import _XOOG_BUILD_VARS from "../../../DataStructures/Build_Vars";
import { useHistory } from "react-router";

let RequestCards=new Array();

const StudentRequests = (props) =>{

    const history=useHistory();
    const initialArray = new Array();

    const [testCard, setTestCard]=useState("");

   

    const courseDetails =useSelector((state) => {
		return state.courseReducer;
	});

    console.log('courseDetails', courseDetails);

    let index=0;

   /* setTestCard(studentRequestList.map((studentObject)=>{
        if(studentRequestList.length>0){
   
             index++;
           console.log(studentObject, courseDetails.courseName, courseDetails.courseId);
   
              return( <RequestCard studentRequestData={studentObject} courseId={courseDetails.courseId} courseName={courseDetails.courseName}/>);
        }
       })
    );
    */
    
   
    console.log("test card", testCard);



    

   const getStudentData = () =>{
        if(testCard.length<=1){
            
            
            console.log("inside get");
            setTestCard("");
            
            db.collection(_XOOG_BUILD_VARS.COLLECTION.HOSTS).doc(auth.currentUser.uid).collection('Students').where("studentRequested","==",true).get().then((docs)=>{
                if(docs.empty){
                    setTestCard((prevState)=>[...prevState, <p>Get More Reviews to receive student Progress</p>]);

                }

                docs.forEach((doc)=>{
                    console.log("doc get", doc.data());
                   
                    let studentObject ={
                        "name":doc.data().name,
                        "status": 'contacted',
                        "timeofEnquiry":doc.data().dateOfStudentRequest,
                        "studentId":doc.data().userId,
                        "courseId":doc.data().courseId,
                        "courseName":doc.data().courseName
                      
                    };

                  

                  
                    setTestCard((prevState)=>[...prevState, <RequestCard studentRequestData={studentObject} courseId={courseDetails.courseId} courseName={courseDetails.courseName}/>]);

                    
                })
            })  
        }
   }

 


    useEffect(()=>{
        console.log("course Details use Effect");
        if(auth.currentUser){
            if(courseDetails=="NoCourses"){
                setTestCard((prevState)=>[...prevState, <p>Create New Course To get More Students</p>]);

            }else{
                if(courseDetails==null){
                    console.log("course details is null");
                    setTestCard((prevState)=>[...prevState, <p>Create Your Course</p>]);

                }else{
                    console.log("set course data");
                getStudentData();
                }
            }            
        }else{

            history.push('/courses');
        }
    },[courseDetails]);
   
    



  //  if(studentRequestList.length>0){

  





    

    {/*
    
    const studentRequestList = [
        {
            "name":"karishma",
            "status":"contacted",
            "timeofEnquiry":new Date(),
            "studentId":"0NhIOK0AorYzz0LrQsvxlvaRgl83"
        },
        {
            "name":"Ritvik",
            "status":"contacted",
            "timeofEnquiry":new Date(),
            "studentId":"0NhIOK0AorYzz0LrQsvxlvaRgl83"
        },
        {
            "name":"karishma",
            "status":"contacted",
            "timeofEnquiry":new Date(),
            "studentId":"0NhIOK0AorYzz0LrQsvxlvaRgl83"
        }           
    ];

*/}



   



    return(
        <div className={Styles.cardHolder}>
         {  /* <div>{testCard.map((reactObject)=>{
                return (reactObject)
            })}</div>
        */}
        {testCard}
     
         
        </div>

    );
}

export default StudentRequests;
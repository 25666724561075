const initialState = {
    valid:false
}

const authValidReducer = (state=initialState,action)=>{
    console.log('inside reducer');
     switch(action.type){
         case 'SET_USER_VALID':
            console.log('inisde switch'); 
            return{
                ...state,
                valid:true
            };
          
         default:
             console.log('default switch '+state);
             return state;
     }

}

export default authValidReducer;
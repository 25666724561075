import React from 'react'
import './Header.css'
function Header() {
    return (
        <div className='header'>
            <h1>XOOG Hosting Platform</h1>
        </div>
    )
}

export default Header

import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
// const firebaseConfig = {
//     apiKey: "AIzaSyCL0uhs3FsLPqvvGXTkQl_MgmWh0oG2uEE",
//     authDomain: "xoog-75949.firebaseapp.com",
//     databaseURL: "https://xoog-75949.firebaseio.com",
//     projectId: "xoog-75949",
//     storageBucket: "xoog-75949.appspot.com",
//     messagingSenderId: "198000890747",
//     appId: "1:198000890747:web:bd16518311a4126b29e3de",
//     measurementId: "G-Y3FXN2DW7N"
//   };


//   const firebaseApp=firebase.initializeApp(firebaseConfig);
const firebaseConfig = {
  apiKey: "AIzaSyCL0uhs3FsLPqvvGXTkQl_MgmWh0oG2uEE",
  authDomain: "xoog-75949.firebaseapp.com",
  databaseURL: "https://xoog-75949.firebaseio.com",
  projectId: "xoog-75949",
  storageBucket: "xoog-75949.appspot.com",
  messagingSenderId: "198000890747",
  appId: "1:198000890747:web:bd16518311a4126b29e3de",
  measurementId: "G-Y3FXN2DW7N"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const auth = firebaseApp.auth();
export const db = firebaseApp.firestore();
export const storage = firebaseApp.storage();
  

  
import React, { useEffect, useState } from 'react'
import './Button.css'
import { AiOutlineArrowRight } from "react-icons/ai";
import img1 from '../../images/2x2_pink.png'
import img2 from '../../images/2x2_blue.png'
import img3 from '../../images/3x3_blue.png'
import img4 from '../../images/3x3_pink.png'
import img5 from '../../images/Pyra_blue.png'
import img6 from '../../images/Pyra_pink.png'

function Button({changePage,order,object,onClick,eventName,eventId}) {

    const [checkTime,setCheckTime]=useState(false);
    useEffect(()=>{
        const compareTime=()=>{
            // console.log(object.startTime);
            var startTime=new Date(object.startTime);
            var endTime=new Date(object.endTime);
            console.log(startTime, endTime, "Checking date from db");
            var currentDateTime=new Date();
            if(currentDateTime>=startTime && currentDateTime<=endTime){
                setCheckTime(true);
            }
        } 
        compareTime();
    },[])

    function handleChange(){
        onClick(object.eventName,eventName,eventId);
        changePage()
    }
    if(checkTime){
    return (
        <div className={order==='odd'?'button':'buttonEven'} onClick={handleChange}>
        <h1>{object.eventName}</h1>
        {(object.eventName==='3x3'&& order==='even') && (<img src={img4} alt=''/>)}
        {(object.eventName==='3x3'&& order==='odd') && (<img src={img3} alt=''/>)}
        {(object.eventName==='2x2'&&order==='even') && (<img src={img1} alt=''/>)}
        {(object.eventName==='2x2'&&order==='odd') && (<img src={img2} alt=''/>)}
        {(object.eventName==='Pyra'&&order==='even') && (<img src={img6} alt=''/>)}
        {(object.eventName==='Pyra'&&order==='odd') && (<img src={img5} alt=''/>)}

        {/* <img src={img1} alt=''/> */}
        <div className={order==='odd'?'arrow':'arrowEven'}>
            <span></span>
            <span></span>
            <AiOutlineArrowRight className={order==='odd'?'arrowIcon':'arrowIconEven'}/>
        </div>
        </div>
    )
    }else{
        return null;
    }
}

export default Button

import React, { useState } from 'react'
import { useParams } from 'react-router';
import { db } from '../../firebase';

function TableRow({details,name}) {
    let {id,category}=useParams()
    console.log(id,category)
   
    return (
        <tr>
            <td></td>
                        <td>{details.data.Name}</td>
                        <td>{details.data.Phone}</td>
                        <td>{details.data.Email}</td>
                      

</tr>

    )
}

export default TableRow

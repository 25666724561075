import React from "react";
import Card from "./Card/Card";
import Styles from "./Dashboard.module.css";

import { SemiWhite,blue,DarkBlue, White } from "../../uicomponents/color";

const Dashboard = () =>{
    console.log(SemiWhite,DarkBlue);
    let name = "karishma";


    return(
        <div>
    
            <div className={Styles.nameText}>
                Welcome Back, {name}
            </div>
            
            {/* ---------Display Cards -----------------*/}

            <div className={Styles.cardHolder}>
                <div className={Styles.cards}>
                    <Card title="Total Number of Students taught till now" number ="100" backgroundColor={blue}textColor={SemiWhite}  numberColor={DarkBlue}/>
                    <Card title="Number of students enquired" number ="100" backgroundColor={blue} textColor={SemiWhite} numberColor={DarkBlue}/>
                    <Card title="Number of students Joined" number ="100" backgroundColor={blue}textColor={SemiWhite}  numberColor={DarkBlue}/>

                </div>

                <div className={Styles.cards}>
   
                    <Card title="Courses" number ="100" backgroundColor={blue} textColor={SemiWhite} numberColor={DarkBlue}/>
                    <Card title="Competitions" number ="100" backgroundColor={blue} textColor={SemiWhite} numberColor={DarkBlue}/>
                </div>
   
             </div>

         </div>

     );

}

export default Dashboard;
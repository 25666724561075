import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import EventOverButtons from '../Button/EventOverButtons';
import img from '../../images/rectangle-linked-file-linked-file-linked-file.png';
import rubiksimg from '../../images/3x3_blue.png';

import './EventComponent.css';
function EventOver({ number, EventState, onClick, changePage, numberInc }) {
	console.log('this is number :?????????????????????', EventState.faculty);
	
	const id = EventState.id;
	const [odd, setOdd] = useState(true);
	const [show, setShow] = useState(false);
	const [SHOWUP, setSHOWUP] = useState(false);
	useEffect(() => {
		const checkTime = () => {
			var startTime = new Date(EventState.startTime);
			var endTime = new Date(EventState.endTime);
			var currentDateTime = new Date();
			if (currentDateTime > endTime) {
				console.log("goinin")
				setShow(true);
				numberInc();
			}
			console.log(startTime, endTime, 'eventComponent checking YOOOOOOOOOOOOO');
		};
		checkTime();
	}, []);


	if (show) {
		return (
			<div className='eventComponent'>
				<div className='eventComponentName' onClick={() => setSHOWUP(true)}>
					<h1 className='even'>
						{EventState.EventName}
					</h1>
					<div className="imgcontainer">
						<img src={ EventState.faculty=="art"? img:rubiksimg} alt="" />
					</div>
					{/* <button
						className={number % 2 === 0 ? 'evenButton' : 'oddButton'}
						onClick={updateShow}>
						Compute
					</button> */}
				</div>

				{SHOWUP && (
					<div className='eventTypeButtonsoverlay'>
						<div className='closebutton' onClick={() => setSHOWUP(false)}>
							X
						</div>
						<div className="doubleclick">
							Double click on the cards
						</div>
						<div className='eventTypeButtons'>
							{EventState.puzzle.map((object) => {
								return (
									<EventOverButtons
										object={object}
										changePage={changePage}
										onClick={onClick}
										eventName={EventState.EventName}
										eventId={EventState.id}
									/>
								);
							})}
						</div>
					</div>
				)}
			</div>
		);
	} else {
		return null;
	}
}

export default EventOver;

import { combineReducers } from "redux";
import authValidReducer from "./authValidReducer";
import CourseReducer from "./courseReducer";
import EventReducer from "./EventReducer";
import isLoginReducer from './isLoginReducer';


const allReducers = combineReducers({
  authValid: authValidReducer,
  EventReducer:EventReducer,
  courseReducer: CourseReducer,
  loginReducer:isLoginReducer
});

export default allReducers;
import React, { useEffect, useState } from "react";
import Tab from "../../uicomponents/Tab/Tab";
import AllStudents from "./AllStudents/AllStudents";
import CurrentStudents from "./CurrentStudents/CurrentStudents";
import StudentRequests from "./StudentRequests/StudentRequests";

import Styles from "./Students.module.css";
import {db,auth} from "../../firebase";
import { useSelector, useDispatch } from 'react-redux';
import _XOOG_BUILD_VARS from "../../DataStructures/Build_Vars";
import { setCourseDetails } from "../../react-redux/action/action";
import { useHistory } from "react-router";
import { ElectricScooterSharp, ElevatorSharp } from "@mui/icons-material";

let courseDoc=[];

const Students=()=>{

    const [hostId,setHostId]=useState("");

    const history=useHistory();
    const initialArray = new Array();
    
    const [courseContents,setCourseContents]=useState(initialArray);
    const dispatcher = useDispatch();

    useEffect(()=>{
       

       
    },[]);

    auth.onAuthStateChanged((host)=>{
        if(host){
            setHostId(host.uid);
        }else{
            history.push('/login');
        }
    })

  

   // const hostId="lIRcUkXPcOW5Qf39WfAb8vS8tXt2";
    
    const [activeCourseIndex,setActiveCourseIndex]=useState(0);

    const getCourseContent=(coursesTutored)=>{
        
        console.log("coursesTutore", coursesTutored);
        if(coursesTutored!=null){
        if(courseContents.length==0){
     
        coursesTutored.forEach((course,i)=>{
            db.collection(_XOOG_BUILD_VARS.COLLECTION.LEARNING_MODULES).doc(course).get().then((doc)=>{
                if(doc!=null){
                    let courseObject = {"courseId":course,"courseName":doc.data().ModuleName}
                   setCourseContents((prevState)=>[...prevState,courseObject]);
                   if(i==0){
                    dispatcher(setCourseDetails(courseObject));
                }
                }

                
            });
 
        });
    }


    }else{
        
    }
      
    }
    
    const getCourse = ()=>{
        
   
        db.collection(_XOOG_BUILD_VARS.COLLECTION.HOSTS).doc(hostId).get().then((doc)=>{


            if(doc.exists){
            courseDoc=doc.data();
            console.log("course Doc",doc.data());
            getCourseContent(courseDoc.coursesTutored);
            }
        });

    }

  

    useEffect(()=>{

        if(hostId!==""){

            getCourse();
        }
    },[hostId]);

  

    

    var tabContents = [
        {
            "title":"Student Requests",
            "component":<StudentRequests courseId={courseContents[activeCourseIndex]}/>
        },
        
      /* {
            "title":"Current Students",
            "component":<CurrentStudents courseId={courseContents[activeCourseIndex]}/>
        },
        {
            "title":"All Students",
            "component":<AllStudents courseId={courseContents[activeCourseIndex]}/>
        }*/
    ];

    console.log("tab contents", tabContents);


    

    const onClickCourseButton = (event) =>{
         
        if(event.target.id != activeCourseIndex){
            
            setActiveCourseIndex(event.target.id);
            dispatcher(setCourseDetails(courseContents[event.target.id]));
				
                  
        }
    }
    
   

    let index = 0;
    let CourseButtons = courseContents.map((object)=>{
      
        let cssclassname=Styles.activeCourseButton;
      
     
        if(activeCourseIndex == index){
           
            cssclassname=Styles.activeCourseButton;
         
        }else{
        
           cssclassname=Styles.normalCourseButton;
           
        }
  
        index++;
        
        return (<button  id={index-1} onClick={onClickCourseButton} className={cssclassname} >{object.courseName}</button>)
    })

    return(
        <div className={Styles.rootContainer}>
           
       { /*    <div className={Styles.buttonContainer}>
                {CourseButtons}
            </div>
    */}
            
            <Tab tabcontent={tabContents} courseId={courseContents[activeCourseIndex]}/>


        
        </div>
    );
}

export default Students;
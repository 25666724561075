import { act } from "react-dom/test-utils";

const TAG='EventReducer ';

const EventReducer = (state=null,action)=>{
    console.log('inside Event reducer');
     switch(action.type){
         case 'SET_EVENT_DETAILS':
            console.log(TAG,action.payload); 
            return{
                ...action.payload   
            };
          
         default:
             console.log('default switch '+state);
             return state;
     }

}

export default EventReducer;